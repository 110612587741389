import { Button } from 'components/Button';
import * as S from './styles'
import { fetchAxiosAdmin, fetchAxiosPayment } from 'services/http';
import { usePaymentProvider } from 'context/paymentContext';
import { useNavigate } from 'react-router-dom';
import { useUserProvider } from 'hooks/userContext';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import useAlert from 'components/Notification';
import { useAnyProvider } from 'hooks/Context';
import { useSessionStorage } from 'context/sessionStorage';
import { BlockedUserAlert } from '../../components/BlockedUserAlert';
import { discountNightValue } from '../../utils/discount';
import { useDateDifference } from 'hooks/useDateDifference';
import { ReserverDataProps } from 'pages/Product/types';
import usePaymentStore from 'pages/Payment/components/PaymentCard/useStorage';

type CardInfoProps = {
    img: string;
    title: string;
    description: string;
}

const CardInfo = ({ ...card }: CardInfoProps) => {
    return (
        <S.CardInfo>
            <S.CardHeader>
                <img src={card.img} alt="" />
                <p>{card.title}</p>
            </S.CardHeader>
            <S.CardRow>
                <strong>{card.description}</strong>
            </S.CardRow>
        </S.CardInfo>
    )
}

export const PixPayment = () => {

    const [reserverInfo, setReserverInfo] = useState<any>({})
    const [blockedUser, setBlockedUser] = useState<boolean>(false)
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })
    const { getFromSessionStorage } = useSessionStorage()
    const { pixDiscountedTotalValue, pixDiscountedTotalValueMidStay, paymentData, pixDiscountedNightValue } = usePaymentStore()

    const { setIsLoading } = usePaymentProvider()
    const { customer, token, userDocument, userAccessToken, userEmail, userPhone, userName, userSurname, userCustomerId } = useUserProvider()
    const { date } = useAnyProvider()
    const navigate = useNavigate();
    const { dayDifference } = useDateDifference(date)
    const infoPaymentPix: CardInfoProps[] = [
        {
            img: require('assets/svg/payment/ClockCountdown.svg').default,
            title: 'Aprovação imediata',
            description: 'O pagamento realizado com Pix é processado em pouco tempo',
        },
        {
            img: require('assets/svg/payment/ShieldCheck.svg').default,
            title: 'Transação segura',
            description: 'O PIX foi desenvolvido pelo Banco Central para facilitar suas compras garantindo a proteção dos seus dados.',
        },
        {
            img: require('assets/svg/payment/ShoppingBag.svg').default,
            title: 'Compra com facilidade',
            description: 'Basta acessar seu Internet Banking, e escanear o QR Code ou digitar o código.',
        },
    ]

    const pixIcon = require('assets/svg/payment/PixIcon.svg').default

    useEffect(() => {
        const reserver: ReserverDataProps = getFromSessionStorage('reserver')
        setReserverInfo(reserver)
    }, []);

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const paymentType = 'PIX';

    function isSpecificAxiosErrorMessage(error: unknown): boolean {
        const axiosError = error as AxiosError;
        return !!axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Cliente bloqueado, favor entrar em contato com o suporte.";
    }

    const cleaningValue = paymentData?.cleaningValue ? paymentData?.cleaningValue : 0

    //Pix Payment
    const handlePayPix = async () => {
        setIsLoading(true)
        try {

            try {
                const description = `${reserverInfo.nameAcc} - ${reserverInfo.nameBuilding} | Data: ${reserverInfo.arrival_date} - ${reserverInfo.departure_date}`
                const { data: dataReserve } = await fetchAxiosAdmin.post('site/set-booking', {
                    "accommodation_code": reserverInfo.accommodation_code,
                    "accommodation_id": reserverInfo.accommodation_id,
                    "arrival_date": reserverInfo.arrival_date,
                    "departure_date": reserverInfo.departure_date,
                    "booking_type": reserverInfo.booking_type,
                    "customer_id": userCustomerId !== '' ? userCustomerId : reserverInfo.user.customer_id,
                    "email": userEmail !== '' ? userEmail : reserverInfo.user.email,
                    "adults_number": reserverInfo.adults_number,
                    "name": userName !== '' ? userName : reserverInfo.user.name,
                    "web": "Yogha.com.br",
                    "ref_stays": reserverInfo.ref_stays,
                    "rental_price": dayDifference < 27 && reserverInfo?.rental_price ? pixDiscountedNightValue(paymentType) : pixDiscountedTotalValueMidStay(paymentType) - cleaningValue,
                    "total_price": dayDifference < 27 ? pixDiscountedTotalValue(paymentType) : pixDiscountedTotalValueMidStay(paymentType),
                    "surname": userSurname !== '' ? userSurname : reserverInfo.user.surname,
                    "dni": userDocument !== '' ? userDocument : reserverInfo.user.dni,
                    "telephone": userPhone !== '' ? userPhone : reserverInfo.user.phone,
                    "services": reserverInfo.services,
                    "token": userAccessToken,
                    "discount": true
                })
                try {
                    const formData = new FormData();
                    formData.append("customer", customer);
                    formData.append("billingType", paymentType);
                    formData.append("dueDate", formattedDate);
                    formData.append("value", dayDifference < 27 ? String(pixDiscountedTotalValue(paymentType)) : String(pixDiscountedTotalValueMidStay(paymentType)));
                    formData.append("token", token);
                    formData.append("description", description);

                    formData.append("booking", dataReserve.id);
                    const response = await fetchAxiosPayment.post('/charge', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    })
                    if (response.status === 200) {
                        setIsLoading(false)
                        navigate(`/payment/PIX_CODE/${response.data.charge_id}`)
                    }

                } catch (error) {
                    navigate(`/payment/${dataReserve.message.id.replace('pay_', '')}/erro-de-pagamento`)
                }
            } catch (error: unknown) {
                if (isSpecificAxiosErrorMessage(error)) {
                    setIsLoading(false)
                    setBlockedUser(true)
                } else {
                    navigate(`/payment/${customer}/erro-de-pagamento`)
                }
            }

        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Verifique as informações do usuario") {
                showAlert({ active: true, title: 'Erro ao válidar documento, por favor insira o documento correto', type: "ERROR" })
            }
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <>
            <S.Header>
                <span>Forma de pagamento escolhido</span>

                <S.InputPayment>
                    <S.InputRow>
                        <img src={pixIcon} alt="" />
                        <p>PIX</p>
                    </S.InputRow>
                    <div style={{ alignSelf: 'flex-end' }}>
                        <S.ButtonContainer to={{
                            pathname: '/payment',
                        }}>
                            <Button onClickButton={() => { }} size='small' title='Alterar' type='secondary' heightSize={"30px"} />
                        </S.ButtonContainer>
                    </div>
                </S.InputPayment>
            </S.Header>
            <S.ContainerInformation>
                {infoPaymentPix.map((card) => {
                    return (
                        <CardInfo key={card.title} {...card} />
                    )
                })}
            </S.ContainerInformation>
            <Button onClickButton={handlePayPix} size='large' title='Gerar PIX' type='primary' widthSize={'245px'} />
            <Alert />
            <BlockedUserAlert blockedUser={blockedUser} setIsBlockedUser={setBlockedUser} />
        </>
    )
}
