import * as S from './styles'
import usePaymentStore, { DataSessionPaymentProps } from './useStorage';
import { formatValue } from 'utils/formatValue';

interface RenderMidStayProps {
    discount: boolean;
    nights: number;
    dataPayment: DataSessionPaymentProps;
    expensesData: any;
    discountDifference: number;
    discountValue: number;
    hasCodePix: string | null;
    typePayment: string;
    totalValue: number;
}
export const useRenderMistay = ({ discount, dataPayment, expensesData, discountDifference, discountValue, hasCodePix, typePayment }: RenderMidStayProps) => {

    const { pixDiscountedTotalValueMidStay, pixDiscountedNightValueMidStay, recoverNightMidStayByPixCode, recoverTotalValueMidStayByPixCode } = usePaymentStore()

    const RenderMidStayCard = () => {
        return (
            <S.PaymentContainer>
                <S.Rows discount={discount}>
                    <p>Aluguel</p>
                    <S.ContainerValues discount={discount}>
                        {discount && <span>De</span>}
                        <S.ContainerPixDiscount discount={discount}>
                            <S.RowTotalValue>
                                <S.Prefix>R$</S.Prefix>
                                <S.RowValues >
                                    {hasCodePix !== '' ? formatValue(recoverNightMidStayByPixCode()) : expensesData.definedDailyValue.toFixed(0) === '0' && dataPayment?.midStayValue ? formatValue(pixDiscountedNightValueMidStay(typePayment)) : formatValue(pixDiscountedNightValueMidStay(typePayment))}
                                </S.RowValues>
                            </S.RowTotalValue>
                        </S.ContainerPixDiscount>
                    </S.ContainerValues>
                    {discount && <S.ContainerValues >
                        <label>R$</label>
                        <S.RowValues>
                            {discountDifference.toFixed(0)},00
                        </S.RowValues>
                    </S.ContainerValues>}
                    {discount && <S.Discount>
                        Você economiza R$ {discountValue.toFixed(0)},00(10%)
                    </S.Discount>}
                </S.Rows>
                <S.Rows>
                    <p>Condomínio</p>
                    <S.ContainerValues>
                        <label>R$</label>
                        <S.RowValues>
                            {formatValue(dataPayment?.condominiumValue)}
                        </S.RowValues>
                    </S.ContainerValues>
                </S.Rows>
                <S.Rows>
                    <p>IPTU</p>
                    <S.ContainerValues>
                        <label>R$</label>
                        <S.RowValues>
                            {formatValue(dataPayment?.iptu)}
                        </S.RowValues>
                    </S.ContainerValues>
                </S.Rows>
                <S.Rows>
                    <p> {dataPayment?.weekValue} Limpezas</p>
                    <S.ContainerValues>
                        <label>R$</label>
                        <S.RowValues>
                            {dataPayment.difference >= 27 && formatValue(dataPayment?.cleaningValue)}
                        </S.RowValues>
                    </S.ContainerValues>
                </S.Rows>
                <S.Rows>
                    <p>Pacote Yogha</p>
                    <S.ContainerValues>
                        <label>R$</label>
                        <S.RowValues>
                            {formatValue(dataPayment?.pacoteYogha)}</S.RowValues>
                    </S.ContainerValues>
                </S.Rows>
                <S.TotalRow>
                    <S.Total>Total</S.Total>
                    <S.ContainerPixDiscount>
                        <S.PrefixTotal hasDiscount={typePayment === 'PIX' || !!hasCodePix}>R$</S.PrefixTotal>
                        <S.TotalValue hasDiscount={typePayment === 'PIX' || !!hasCodePix}>
                            {hasCodePix ? formatValue(recoverTotalValueMidStayByPixCode()) : dataPayment.total === 0 ? formatValue(dataPayment.total) : formatValue(dataPayment.total)}
                        </S.TotalValue>
                        {typePayment === 'PIX' && (
                            <S.RowTotalValue>
                                <S.PrefixTotal   >R$</S.PrefixTotal>
                                <S.TotalValue>
                                    {hasCodePix ? formatValue(dataPayment.total) : pixDiscountedTotalValueMidStay(typePayment) === 0 ? formatValue(pixDiscountedTotalValueMidStay(typePayment)) : formatValue(pixDiscountedTotalValueMidStay(typePayment))}
                                </S.TotalValue>
                            </S.RowTotalValue>
                        )}
                    </S.ContainerPixDiscount>
                </S.TotalRow>
                {/* {validationCupom && <S.FooterButtons>
                    <S.ContainerUndestandValues>

                        <S.CupomContainer onClick={onClickCupom}>
                            <img src={cupomIcon} alt="" /> <p>Possui algum cupom?</p>
                        </S.CupomContainer>
                    </S.ContainerUndestandValues>
                </S.FooterButtons>} */}
            </S.PaymentContainer>
        )

    }

    return { RenderMidStayCard }
}