import * as S from './styles'
import usePaymentStore, { DataSessionPaymentProps } from './useStorage';
import { formatValue } from 'utils/formatValue';

interface RenderShortStayProps {
    discount: boolean;
    nights: number;
    dataPayment: DataSessionPaymentProps;
    expensesData: any;
    discountDifference: number;
    discountValue: number;
    hasCodePix: string | null;
    typePayment: string;
    totalValue: number;
    weekValue: number;
    difference: number;
}

//nights >= 27 &&
export const useRenderShortStay = ({ discount, dataPayment, expensesData, discountDifference, discountValue, hasCodePix, typePayment, difference, weekValue }: RenderShortStayProps) => {

    const { pixDiscountedTotalValue, pixDiscountedNightValue, recoverNightByPixCode, recoverTotalValueByPixCode } = usePaymentStore()

    const RenderShortStayCard = () => {
        return (
            <S.PaymentContainer>
                <S.Rows discount={discount}>
                    <p>{difference === 0 ? dataPayment?.difference : difference} Noites</p>
                    <S.ContainerValues discount={discount}>
                        {discount && <span>De</span>}
                        <S.ContainerPixDiscount discount={discount}>
                            <S.RowTotalValue>
                                <S.Prefix>R$</S.Prefix>
                                <S.RowValues >
                                    {hasCodePix !== '' ? formatValue(recoverNightByPixCode()) : expensesData.definedDailyValue.toFixed(0) === '0' && dataPayment?.nightValue ? formatValue(pixDiscountedNightValue(typePayment)) : formatValue(pixDiscountedNightValue(typePayment))}
                                </S.RowValues>
                            </S.RowTotalValue>

                        </S.ContainerPixDiscount>
                    </S.ContainerValues>
                    {discount && <S.ContainerValues >
                        <label>R$</label>
                        <S.RowValues>
                            {discountDifference.toFixed(0)},00
                        </S.RowValues>
                    </S.ContainerValues>}
                    {discount && <S.Discount>
                        Você economiza R$ {discountValue.toFixed(0)},00(10%)
                    </S.Discount>}
                </S.Rows>
                <S.Rows>
                    <p>{weekValue === 0 ? dataPayment?.weekValue : weekValue} Limpeza{weekValue > 1 || dataPayment && dataPayment?.weekValue > 1 && 's'}</p>
                    <S.ContainerValues>
                        <label>R$</label>
                        <S.RowValues>
                            {weekValue !== 0 ? formatValue(dataPayment?.cleaningValue) : dataPayment?.valueWeek}
                        </S.RowValues>
                    </S.ContainerValues>
                </S.Rows>
                <S.Rows>
                    <p>Pacote Yogha</p>
                    <S.ContainerValues include>
                        <label>incluso</label>
                    </S.ContainerValues>
                </S.Rows>
                <S.TotalRow>
                    <S.Total>Total</S.Total>
                    <S.ContainerPixDiscount>
                        <S.RowTotalValue >
                            <S.PrefixTotal hasDiscount={typePayment === 'PIX' || !!hasCodePix} >R$</S.PrefixTotal>
                            <S.TotalValue hasDiscount={typePayment === 'PIX' || !!hasCodePix}>
                                {hasCodePix ? formatValue(recoverTotalValueByPixCode())
                                    :
                                    dataPayment.total === 0 ? formatValue(dataPayment.total) && formatValue(dataPayment.total)
                                        : formatValue(dataPayment.total)}
                            </S.TotalValue>
                        </S.RowTotalValue>
                        {typePayment === 'PIX' && (
                            <S.RowTotalValue>
                                <S.PrefixTotal >R$</S.PrefixTotal>
                                <S.TotalValue>
                                    {hasCodePix ? formatValue(dataPayment.total) : pixDiscountedTotalValue(typePayment) === 0 ? formatValue(pixDiscountedTotalValue(typePayment)) : formatValue(pixDiscountedTotalValue(typePayment))}
                                </S.TotalValue>
                            </S.RowTotalValue>
                        )}
                    </S.ContainerPixDiscount>
                </S.TotalRow>
                {/* {validationCupom && (
                        <S.FooterButtons>
                            <S.ContainerUndestandValues>

                                <S.CupomContainer onClick={onClickCupom}>
                                <img src={cupomIcon} alt="" /> <p>Possui algum cupom?</p>
                            </S.CupomContainer>
                            </S.ContainerUndestandValues>
                        </S.FooterButtons>
                    )} */}
            </S.PaymentContainer>
        )

    }

    return { RenderShortStayCard }
}