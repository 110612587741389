import styled, { css } from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../../constants/styleConstants";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../../constants/fontConstants";
import { PostCard } from "../types";

export const PostContainer = styled.div<Pick<PostCard, 'titleButton'>>`
  display: flex;
  flex-direction: column;

  max-width: 383px;
  width: 100%;

  white-space: pre-wrap ;

  row-gap: 16px;

  cursor: pointer;

  &:hover {
    h1{
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    max-width: unset;
    width: 100%;
  }
  `

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 8px;
`;

export const Title = styled.h1`
  text-align: left;
  ${FONT.BODY.BODY_MEDIUM[600]}
  color: ${NEUTRAL_COLORS.HIGH_DARK};

  word-wrap: break-word;
  white-space: pre-wrap;
  height: 62px;
`;

export const HighlightText = styled.h2`
${FONT.BODY.BODY_MEDIUM[600]}
color: ${PRIMARY_COLORS.MEDIUM};
text-align: left;
word-wrap: break-word;
white-space: pre-wrap;
  `;

export const Image = styled.img`

  border-radius: 8px;

  width: 100%;
  height: 240px;

  background-color: ${NEUTRAL_COLORS.DARK};

  object-fit: cover;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;

  gap: 10px;

  p{
    ${FONT.BODY.BODY_SMALL[400]}
  }
`;