import { useEffect, useState, useCallback, useRef } from 'react';
import { CardSeeMore, CardSeeMoreProps } from '../CardSeeMore';
import * as S from './styles';
import { ButtonCarousel } from '../ButtonCarousel';
import Draggable from 'react-draggable';
import { useScreenWidth } from 'utils/useScreenWidth';
import MobileCarousel from 'components/MobileCarousel';

export type BannerProps = {
  notices: CardSeeMoreProps[];
  imageNotice: any[];
};

export const Banner = (props: BannerProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragX, setDragX] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [mouseDownTime, setMouseDownTime] = useState<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const autoSlideTimer = useRef<NodeJS.Timeout | null>(null);

  const breakpoint = useScreenWidth()

  const { notices, imageNotice } = props;

  const updateSlide = useCallback(
    (newSlide: any) => {
      setCurrentSlide(newSlide);
      setDragX(-newSlide * 1208);
      setIsTransitioning(true);
      setTimeout(() => setIsTransitioning(false), 300);
    },
    [setCurrentSlide, setDragX, setIsTransitioning]
  );

  const onDragStop = (e: any, data: any) => {

    const timeDiff = Number(new Date()) - mouseDownTime;

    if (timeDiff < 125) {
      handleClick();
    }
    setMouseDownTime(null);
    const delta = data.x - dragX;
    const newSlide = currentSlide - Math.round(delta / 1208);

    const maxX = -((Math.min(imageNotice.length, 3) - 1) * 1208);
    const newX = Math.min(0, Math.max(data.x, maxX));
    setDragX(newX);

    if (newSlide < 0) {
      updateSlide(0);
    } else if (newSlide > Math.min(imageNotice.length, 3) - 1) {
      updateSlide(Math.min(imageNotice.length, 3) - 1);
    } else {
      updateSlide(newSlide);
    }
    setIsDragging(false);
  };


  const goBackward = useCallback(() => {
    const newSlide = Math.max(currentSlide - 1, 0);
    updateSlide(newSlide);
    resetAutoSlideTimer();
  }, [currentSlide, updateSlide]);

  const goForward = useCallback(() => {
    const maxSlide = Math.min(imageNotice.length, 3) - 1;
    const newSlide = currentSlide === maxSlide ? 0 : currentSlide + 1;
    updateSlide(newSlide);
    resetAutoSlideTimer();
  }, [currentSlide, imageNotice.length, updateSlide]);

  const handleMouseDown = (e: any) => {
    setMouseDownTime(Number(new Date()));
  };

  const maxDragWidth = imageNotice.length * 1208;

  const handleClick = () => {
    imageNotice[currentSlide].onClick();
  }

  const onStart = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }

  const resetAutoSlideTimer = useCallback(() => {
    if (autoSlideTimer.current) {
      clearTimeout(autoSlideTimer.current);
    }
    autoSlideTimer.current = setTimeout(goForward, 5000);
  }, [goForward]);

  useEffect(() => {
    resetAutoSlideTimer();
    return () => {
      if (autoSlideTimer.current) {
        clearTimeout(autoSlideTimer.current);
      }
    };
  }, [resetAutoSlideTimer]);

  return (
    <S.Container>
      {breakpoint ? (
        <MobileCarousel />
      ) : (
        <>
          <S.PortraitCarousel>
            <Draggable
              axis="x"
              bounds={{ left: -maxDragWidth, right: 0 }}
              position={{ x: dragX, y: 0 }}
              onStop={onDragStop}
              onMouseDown={handleMouseDown}
              onStart={onStart}
            >
              <S.CarouselContent
                style={{
                  transform: `translateX(${dragX}px)`,
                  transition: isTransitioning ? 'transform 300ms' : 'none',
                }}
              >
                {imageNotice.slice(0, 3).map((imageNotice: any, i: any) => (
                  <img key={imageNotice.icon + i} src={imageNotice.icon} alt="" />
                ))}
              </S.CarouselContent>
            </Draggable>
            <S.BorderRight />
            <S.BorderLeft />
            <S.BorderPortrain />
          </S.PortraitCarousel>
          <S.ButtonContainer>
            <ButtonCarousel onClick={goBackward} direction="left" />
            {imageNotice.slice(0, 3).map((_: any, i: number) => {
              return <S.Bullet key={i} active={currentSlide === i ? true : false} />;
            })}
            <ButtonCarousel onClick={goForward} direction="right" />
          </S.ButtonContainer>
        </>
      )}
      <S.CardContainer>
        {notices.map((notice: CardSeeMoreProps) => (
          <CardSeeMore
            key={notice.description}
            title={notice.title}
            description={notice.description}
            icon={notice.icon}
            onClick={notice.onClick}
          />
        ))}
      </S.CardContainer>
    </S.Container>
  );
};
