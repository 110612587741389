import { useNavigate } from "react-router-dom";
import * as PS from "../styles";
import * as S from "./styles";
import { BlogCard } from "../types";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export const Blog: React.FC<BlogCard> = ({ ...card }) => {
  const history = useNavigate();

  const convertDateFormat = (dateStr: string) => {
    if (!dateStr) return '';

    // Parse the date string using parseISO
    const dateObj = parseISO(dateStr);

    // Check if the date is valid
    if (isNaN(dateObj.getTime())) {
      return '';
    }

    // Format the date
    const convertedDate = format(dateObj, 'dd MMM \'de\' yyyy', { locale: ptBR });
    return convertedDate;
  };

  const handleClick = () => {
    history(`/resultado/${card.id}`, { state: card });
  };

  return (
    <>
      <S.PostContainer onClick={card.onClick}>
        <S.Image src={card.image} />
        <S.HeaderRow>
          <S.HighlightText>{card.category}</S.HighlightText>
          <S.Title>{card.description}</S.Title>
        </S.HeaderRow>
        <S.FooterRow>
          <p>{convertDateFormat(card.createdAt as string)}</p><p>|</p> <p>Leitura: {card.readingTime} Minutos</p>
        </S.FooterRow>
      </S.PostContainer>
    </>)

}