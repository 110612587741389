import styled from "styled-components";

export const Container = styled.div<{noHasMenuBar: boolean}>`
    position: fixed;
    bottom: 32px;

    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25d366;
    border-radius: 999%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    width: 62px;
    height: 62px;

    a, img{
        width: 40px;
        height: 40px;
    }

    @media (max-width: 1366px) {
        right: 16px;
    }
    @media (min-width: 1366px) {
        right: calc(50% - 600px + 16px);
    }
    @media (max-width: 784px) {
        bottom: 84px;
        ${({noHasMenuBar}) => noHasMenuBar && 'bottom: 32px;'}
    }
`