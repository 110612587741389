import { LinkCard } from "components/Card/types"
import { useAnyProvider } from "hooks/Context"
import { useSpaProvider } from "hooks/spaRouterContext"
import { useNavigate } from "react-router-dom"
import { useScreenWidth } from "utils/useScreenWidth"

type UseHome = {
    accommodations: any;
    buildings: any;
    blog: any;
    isFetching: boolean

}

export const useHome = ({ accommodations,blog,buildings,isFetching }: UseHome) => {

    const { setExpanded, setPageChosen } = useSpaProvider();

    const { setSearchType, setSearchValue } = useAnyProvider()

    const breakpoint = useScreenWidth()

    const openHeader = () => {
      if (breakpoint) {
        navigate('/search-location')
        setPageChosen(2)
      } else {
        setTimeout(() => {
          setExpanded(false)
        }, 10);
        setTimeout(() => {
          setPageChosen(2)
        }, 50);
      }

    }
    const openHeaderPromo = () => {
      if (breakpoint) {
        navigate('/properties?search=Curitiba')
        setSearchValue('Curitiba')
        setSearchType('city')
      } else {
        setTimeout(() => {
          setExpanded(false)
        }, 10);
        setTimeout(() => {
          setPageChosen(1)
        }, 50);
      }

    }

    const navigate = useNavigate()

    const buildingLink: LinkCard = {
        image: require('assets/svg/Units.svg').default,
        buttonText: 'Ver ofertas',
        linkText: "Conheça todas as unidades",
        size: 'unit',
        id: 'unit',
        onClick: openHeader
      }

      const blogLink: LinkCard = {
        image: require('assets/svg/NewBlogNotices.svg').default,
        id: 'blog',
        size: 'post',
        linkText: 'Quer acompanhar essas e outras matérias?',
        buttonText: 'Visitar blog',
        onClick: () => window.location.href = `https://blog.yogha.com.br/`
      }

      const postsWithLink = [...blog, blogLink]
      const notices = [
        {
          title: 'Moradia Flexível',
          description: 'Uma forma de morar descomplicada. Encontre o apê que é a sua cara e fique o tempo que quiser. ',
          icon: require('assets/svg/MapPin.svg').default,
          onClick: () => navigate('/moradia-flexivel/')
        },
        {
          title: 'Para proprietários',
          description: 'Obtenha a máxima rentabilidade com o seu imóvel sem sair de casa com o Yogha Gestão.',
          icon: require('assets/svg/UsersThree.svg').default,
          onClick: () => window.location.assign('https://negocios.yogha.com.br/')
        },
        {
          title: 'Para incorporadoras',
          description: 'Transforme o seu empreendimento em um prédio inteligente com o ecossistema Yogha',
          icon: require('assets/svg/Briefcase.svg').default,
          onClick: () => navigate('/incorporadores/')
        },
      ]

      const image = [
        {
          icon: require('assets/image/notices/InicialModalNews1.webp'),
          onClick: () => navigate('/moradia-flexivel/')
        },
        {
          icon: require('assets/image/notices/InicialModalNews2.webp'),
          onClick: () => navigate('')
        },
        {
          icon: require('assets/image/notices/InicialModalNews3.webp'),
          onClick: () => window.location.assign('https://negocios.yogha.com.br/')
        },
      ]

      const imageMobile = [
        {
          icon: require('assets/image/notices/MobileNews1.webp'),
          onClick: () => navigate('/moradia-flexivel/')
        },
        {
          icon: require('assets/image/notices/MobileNews2.webp'),
          onClick: () => navigate('')
        },
        {
          icon: require('assets/image/notices/MobileNews3.webp'),
          onClick: () => window.location.assign('https://negocios.yogha.com.br/')

        }
      ]

      const accomodationsLink: LinkCard = {
        image: require('assets/svg/Percent.svg').default,
        buttonText: 'Ver ofertas',
        linkText: "Conheça todas as nossas ofertas",
        size: 'unit',
        id: 'all',
        onClick: () => openHeaderPromo()
      }

      const accomodationsWithLink = [...accommodations, accomodationsLink]
      const buildingsWithLink = [...buildings, buildingLink]

      return {
        breakpoint,
        navigate,
        accomodationsWithLink,
        buildingsWithLink,
        image,
        imageMobile,
        notices,
        postsWithLink
      }

}

