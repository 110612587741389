import { useRef, useState } from 'react';
import { Modal } from '../Modal';
import { Container, Icon, Photo, PhotoGrid } from './styles';
import { useUserProvider } from '../../hooks/userContext';
import * as S from './styles'
import { Icons } from '../Icons';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../Button';
export const User = () => {

  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const menuIcon = require('assets/svg/List.svg').default;
  const avatarDefault = require('assets/svg/AvatarDefault.svg').default;

  //----------------------------------// icons modal
  const userRegisterIcon = require("assets/svg/user/UserCirclePlus.svg").default
  const logoutIcon = require("assets/svg/user/Logout.svg").default
  const accountIcon = require("assets/svg/user/User.svg").default
  const reservesIcon = require("assets/svg/user/CalendarCheck.svg").default
  const favoritesIcon = require("assets/svg/user/Heart.svg").default
  //----------------------------------//

  const carretRight = require('assets/svg/CaretRightLightGray.svg').default;

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('customer');
    localStorage.removeItem('userName');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPhone');
    localStorage.removeItem('userDocument');
    window.location.reload();
  }

  const { userName, userAvatar } = useUserProvider();

  const validPhoto = (photo: string) => {
    if (photo === undefined || photo === null) {
      return avatarDefault
    } else if (photo.length > 0) {
      return photo
    } else {
      return avatarDefault
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  return (<>
    <Container ref={containerRef} >
      <S.UserContainer>
        <Icon src={menuIcon} />
        <PhotoGrid >
          <Photo src={validPhoto(userAvatar)} />
        </PhotoGrid>
      </S.UserContainer>
      <Modal containerModal={containerRef} setIsOpen={setIsOpen} isOpen={isOpen} >
        <S.ContainerModal>
          <S.Modal user={'Peter'}>
            <S.ModalContent>
              {userName !== '' ? <S.User onClick={() => navigate('/minha-conta')}>
                <img src={validPhoto(userAvatar)} alt="" />
                <div>
                  <h1>Olá,</h1>
                  <h1>{userName}</h1>
                </div>
              </S.User>
                :
                <div>
                  <S.SectionModal>
                    <S.Row>
                      <S.TynyText className='null'>Minha conta:</S.TynyText>
                      <S.SubTitle>Entre para ver suas reservas, favoritos
                        e gerencias sua conta</S.SubTitle>
                    </S.Row>
                    <Button onClickButton={() => navigate('/login')} size='medium' title='Entrar' type='primary' widthSize={'270px'} />
                    <S.ModalNavLink onClick={() => navigate('/signup')}>
                      <Icons icon={userRegisterIcon} width={20} height={19} />
                      <p>Cadastrar-se</p>
                    </S.ModalNavLink>
                  </S.SectionModal>
                </div>}
              {userName !== '' && <S.SectionModal>
                <S.TynyText>Minha conta:</S.TynyText>
                <S.LinksCollumn>
                  <S.ModalNavLink onClick={() => navigate('/minha-conta/informacoes/')}>
                    <img src={accountIcon} alt='' />
                    <p>Informações Pessoais</p>
                  </S.ModalNavLink>
                  <S.ModalNavLink onClick={() => navigate('/minha-conta/reservas/')}>
                    <img src={reservesIcon} alt='' />
                    <p>Minhas reservas</p>
                  </S.ModalNavLink>
                  {/* <S.ModalNavLink onClick={() => navigate('/minha-conta/favorites')}>
                    <img src={favoritesIcon} alt='' />
                    <p>Favoritos</p>
                  </S.ModalNavLink> */}
                  <S.ModalNavLink onClick={handleLogout}>
                    <img src={logoutIcon} alt='' />
                    <p>Sair</p>
                  </S.ModalNavLink>
                </S.LinksCollumn>
              </S.SectionModal>}
              <S.SectionModalLinks>
                <S.TynyText className='space'>Espaço Yogha:</S.TynyText>
                <S.ModalNavLink>
                  <S.CustomLink to={'https://blog.yogha.com.br/'}>Blog da Yogha</S.CustomLink> <img src={carretRight} alt='' />
                </S.ModalNavLink>
                <S.ModalNavLink>
                  <S.CustomLink to={'/moradia-flexivel/'}>Para moradores</S.CustomLink> <img src={carretRight} alt='' />
                </S.ModalNavLink>
                <S.ModalNavLink>
                  <S.CustomLink to={'https://negocios.yogha.com.br/'}>Para proprietários</S.CustomLink> <img src={carretRight} alt='' />
                </S.ModalNavLink>
                <S.ModalNavLink>
                  <S.CustomLink to={'/incorporadores/'}>Para incorporadoras</S.CustomLink> <img src={carretRight} alt='' />
                </S.ModalNavLink>
              </S.SectionModalLinks>
            </S.ModalContent>
          </S.Modal>
        </S.ContainerModal>
      </Modal>
    </Container>
  </>
  );
};
