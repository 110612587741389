import { Button } from "components/Button"
import { Input } from "components/Input";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import fetchAxios from "services/http";
import { useState } from "react";
import { useSessionStorage } from "context/sessionStorage";
import { ProductCard } from "components/ProductCard";
import useAlert from "components/Notification";
import { AxiosError } from "axios";
import { startSession } from "pages/Checkin/hook/useSession";
import { deleteChecking } from "pages/Checkin/utils/deleteCheckin";


export const ValidationToken = () => {

    document.title = "Check-in Online | Yogha";

    const { t } = useTranslation();
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })
    const tokenId = useParams();
    const [token, setToken] = useState<string>(tokenId.id || '');
    const [openLinkModal, setOpenLinkModal] = useState<boolean>(false)
    const [copy, setCopy] = useState<string>("Copiar")

    const { saveToSessionStorage } = useSessionStorage()

    const actualHref: string = window.location.href

    const navigate = useNavigate();

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const validateMaxAccompanying = (maxAccompanying: number) => {
        if (maxAccompanying === 1) {
            return 1
        } else {
            return maxAccompanying - 1
        }
    }

    const handleValidationToken = async () => {
        try {
            const { data: tokenData } = await fetchAxios.post(`/checkin/token/${token}`)
            const data = tokenData.data
            if (data) {
                try {
                    const { data: dataStep } = await fetchAxios.post(`/checkin/create/step/${data.id}`)
                    saveToSessionStorage('checkin-details', { departure_date: data.departure_date, arrival_date: formatDate(data.arrival_date), max_accompanying: validateMaxAccompanying(data.adults_number), booking_id: data.id, customer_id: data.customer_id, checkinId: dataStep.data.id })
                    startSession()
                    navigate(`/check-in/${token}/personal-info`)
                } catch (error) {
                    console.error(error)
                }
            }
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Data do checkin já passou ") {
                showAlert({ active: true, title: t('checkin.initialPage.errorTokenExpiredDate'), type: "ERROR" })
            }
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Erro ao buscar o token") {
                showAlert({ active: true, title: t('checkin.initialPage.errorTokenNotFound'), type: "ERROR" })
            }
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && typeof axiosError.response.data === 'object' && 'idCheckin' in axiosError.response.data && axiosError.response.data.message === "Iniciado mas não finalizado") {
                const checkinId = axiosError.response?.data.idCheckin
                try {
                    await deleteChecking(checkinId as number)
                    try {
                        const { data: tokenData } = await fetchAxios.post(`/checkin/token/${token}`)
                        const dataToken = tokenData.data
                        if (dataToken) {
                            try {
                                const { data: dataStep } = await fetchAxios.post(`/checkin/create/step/${dataToken.id}`)
                                saveToSessionStorage('checkin-details', { departure_date: dataToken.departure_date, arrival_date: formatDate(dataToken.arrival_date), max_accompanying: validateMaxAccompanying(dataToken.adults_number), booking_id: dataToken.id, customer_id: dataToken.customer_id, checkinId: dataStep.data.id })
                                startSession()
                                navigate(`/check-in/${token}/personal-info`)
                            } catch (error) {
                                console.error(error)
                            }
                        }
                    } catch (error) {
                        console.error(error)
                    }
                } catch (error) {
                    console.error(error)
                }



                showAlert({ active: true, title: t('checkin.initialPage.errorTokenNotFound'), type: "ERROR" })
            }
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Já foi preenchido") {
                showAlert({ active: true, title: t('checkin.initialPage.errorHasCheckin'), type: "ERROR" })
            }
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Reserva cancelada") {
                showAlert({ active: true, title: t('checkin.initialPage.errorReserveCanceled'), type: "ERROR" })
            }
        }
    }

    const handleCopyInlineCode = async () => {
        try {
            await navigator.clipboard.writeText(actualHref);
            setCopy("Copiado");
            setTimeout(() => {
                setCopy("Copiar");
            }, 2500);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };


    return (
        <S.ValidationContainer>
            <h1>Check-in</h1>
            <S.ContainerForm>
                <Input width={"100%"} htmlFor="token" onChange={(e) => setToken(e.currentTarget.value)} placeholder="" title={t('checkin.initialPage.reserveToken')} type="" value={token} />
                <S.ContainerButtons>
                    <Button widthSize={"100%"} onClickButton={handleValidationToken} size="large" title={t('checkin.initialPage.buttonCheckin')} type="primary" />
                    <Button widthSize={"100%"} onClickButton={() => setOpenLinkModal(true)} size="large" title={t('checkin.initialPage.youAreNotTheGuest')} type="secondary" />
                </S.ContainerButtons>
            </S.ContainerForm>
            <S.ArticleText>{t('checkin.initialPage.article')}</S.ArticleText>
            <ProductCard width="305px" setIsOpen={setOpenLinkModal} isOpen={openLinkModal} bottomLine title={t('checkin.initialPage.modalTItle')}>
                <p>{t('checkin.initialPage.messageModal')}</p>
                <S.InputPayment>
                    <S.InlineCode>
                        <h5>{actualHref}</h5>
                    </S.InlineCode>
                    <div style={{ alignSelf: 'flex-end' }}>
                        <S.ButtonContainer>
                            <Button onClickButton={handleCopyInlineCode} size='small' title={copy} type='secondary' heightSize={"20px"} widthSize={'100%'} />
                        </S.ButtonContainer>
                    </div>
                </S.InputPayment>
            </ProductCard>
            <Alert />
        </S.ValidationContainer>
    )
}