import { CalendarComponent } from "components/Calendar"
import { useState } from "react"

const blockedDates = [
    '2024-04-09',
    '2025-04-15',
    '2024-04-10',
]

export const Teste = () => {
    const [date, setDate] = useState<any>(null)

    return (
        <div style={{
            paddingTop: '100px',
            overflow: 'auto',
            height: '100vh',
        }}>
            <CalendarComponent date={date} setDate={setDate} blockedDates={blockedDates} />
            <div style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                marginTop: '20px'
            }}>
                <input type="text" value={date ? date[0] : ''} />
                <input type="text" value={date ? date[1] : ''} />
            </div>

        </div>
    )
}