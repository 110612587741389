// TABLE OF COLORS

export const PRIMARY_COLORS = {
  DARK: '#D96824',
  MEDIUM: '#FF7A2A',
  LIGHT: '#FF8E4A',
  LIGHTEST: '#FFD6BD',
} as const;

export const STATUS_COLORS = {
  SUCCESS: '#51B755',
  WARNING: '#E5D54C',
  ERROR: '#ED374C',
  ERROR_HOVER: '#C72437',
  ERROR_ACTIVE: '#AD1728',
  INFO: '#5BADD7',
  DISABLED: '#C9C9C9',
} as const;

export type StatusColorsType = typeof STATUS_COLORS['SUCCESS'] | typeof STATUS_COLORS['WARNING'] | typeof STATUS_COLORS['ERROR'] | typeof STATUS_COLORS['INFO'] | typeof STATUS_COLORS['DISABLED'];

export const NEUTRAL_COLORS = {
  WHITE: '#FFFFFF',
  LIGHT_GRAY: '#EEEEEE',
  GRAY: '#B9B9B9',
  DARK_GRAY: '#818181',
  DARK: '#3E3E3E',
  HIGH_DARK: '#161616',
} as const;
