import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import fetchAxios from "services/http";
import { Helmet } from 'react-helmet-async';
import { linkImage } from "utils/formatterArray";
import { useSessionStorage } from "context/sessionStorage";

export const RedirectPage = () => {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const id = pathname.split('/')[2];

    const { getFromSessionStorage } = useSessionStorage()

    const link = window.location.origin;

    const [metaData, setMetaData] = useState({
        title: "Yogha",
        description: "Descrição do seu conteúdo.",
        image: "URL da imagem do seu conteúdo",
        url: `${link}/h/${id}`
    });

    const redirect = async (pathname: string) => {
        try {
            const res = await fetchAxios(`/accommodations/referenceCode/${pathname}`);
            // Update meta data with the fetched data
            setMetaData({
                title: `Yogha ${res.data.name_ad || ""}`,
                description: res.data.description || "Descrição do seu conteúdo.",
                image: res.data.featured_image ? `${linkImage}${res.data.featured_image}` : "URL da imagem do seu conteúdo",
                url: `${link}/h/${id}`
            });
            const taxSearch = getFromSessionStorage('valueTotalFee')
            if (taxSearch === true) {
                navigate(`/property/${res.data.ref_stays}/${res.data.building_yogha}/?includeTax=true`);
            } else {
                navigate(`/property/${res.data.ref_stays}/${res.data.building_yogha}`);
            }
        } catch (error) {
            navigate('/');
        }
    };

    useEffect(() => {
        redirect(id);
    }, [id]);

    return (
        <>
            <Helmet>
                <meta property="og:title" content={metaData.title} />
                <meta property="og:description" content={metaData.description} />
                <meta property="og:image" content={metaData.image} />
                <meta property="og:url" content={metaData.url} />

                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={metaData.title} />
                <meta name="twitter:description" content={metaData.description} />
                <meta name="twitter:image" content={metaData.image} />

                <title>{metaData.title}</title>
            </Helmet>
        </>
    );
};
