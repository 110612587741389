import styled from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";
import { Icons } from "../Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1366px;
  height: 788px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 64px;


  @media (max-width: 768px) {
    width: 100vw;
    height: unset;
  }
    `;



export const PortraitCarousel = styled.div`
  position: relative;
  padding: 0px 80px;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 16px;
  align-self: flex-start;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const BorderPortrain = styled.div`
position: absolute;
width: 1200px;
height: 488px;
outline: 8px solid white;
border-radius: 16px;
inset: 0;
pointer-events: none;
align-self: center;
justify-self: center;
margin:0px 64px;
margin-left: calc(64px + 16px);

    @media (max-width: 768px) {
    display: none;
}
`;

export const BorderRight = styled.div`
position: absolute;
width: 146px;
height: 490px;
top: 0;
right: calc(100% - 144px);
background-color: white;
pointer-events: none;
align-self: center;
justify-self: center;
margin:0px 64px;
margin-left: calc(64px + 16px);
pointer-events: none;

@media (max-width: 768px) {
    display: none;
}

`;

export const BorderLeft = styled.div`
position: absolute;
width: 150px;
height: 490px;
top: 0;
right: calc(100% - 1430px);
background-color: white;
pointer-events: none;
align-self: center;
justify-self: center;
pointer-events: none;

@media (max-width: 768px) {
    display: none;
}
`;

export const CarouselContent = styled.div`
    display:flex;
    width: 100%;
    height: 488px;
    transition: all 1s ease-in-out;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    cursor: pointer;
    img{
        pointer-events: none;
        position: relative;
        border-radius: 10px;
        height: 488px;
        object-fit: contain;
        max-width: 1206px;

    }

    @media (max-width: 768px) {
        width: 100vw;
       img{
        pointer-events: none;
        position: relative;
        border-radius: none;
        height: unset;
        object-fit: contain;
       }
    }
`;


export const CaroselImage = styled.div`
    width: 1206px;
    /* @media (min-width: 1660px) {
        margin: 0px 13%;
    }
    @media (min-width: 1870px) {
        margin: 0px 17%;
    }
    @media (min-width: 2000px) {
        margin: 0px 24%;
    }
    @media (min-width: 2400px) {
        margin: 0px 29%;
    }
    &:first-child {
        margin-left: 0px;
    } */

    @media (max-width: 768px) {
    width: 100%;
}

    `;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 62px;

    @media (max-width: 768px) {
        width: 100%;
}
    `;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 69px;
  background: ${NEUTRAL_COLORS.WHITE};
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  `;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
  `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    height: 100%;
    /* padding-bottom: 8px; */
    gap: 32px;

    @media (max-width: 768px) {
        width: calc(100% - 60px);
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    `;