import styled, { css } from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";


export const Content = styled.div<{ active: boolean }>`
display: flex;

position: relative;

min-width: 39px;
min-height: 26px;

border-radius: 24px;

${({ active }) => active ? css`
    background-color: ${PRIMARY_COLORS.MEDIUM};
` : css`
    background-color: #C9C9C9;
    `}
`;

export const Toggle = styled.div<{ active: boolean }>`
display: flex;

width: 20px;
height: 20px;

background-color: ${NEUTRAL_COLORS.WHITE};

border-radius: 96px;
border: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

position: absolute;

top: 2px;
${({ active }) => active ? css`
    right: 2px;
` : css`
    left: 2px;
    `}

transition: all 0.2s ease-in-out;
`;