import { useCallback, useEffect, useRef, useState, } from "react"
import * as S from "./styles"

export type SelectOption = {
    label?: string,
    value?: string,
}

export type SelectPropsWithFilterAndMap = {
    filter(arg0: (item: any) => boolean): SelectOption,
    map(arg0: (option: any) => JSX.Element): JSX.Element
} & SelectOption

type SelectProps = {
    disabled?: boolean
    options: SelectOption[]
    value?: SelectOption
    placeholder?: string
    onChange: (value: SelectOption | undefined) => void
    widthDrop: number | string
    name: string,
    label?: string,
    description?: string
    errorText?: string
    viewScreen?: boolean
}


export const errorIconInput = require('../../assets/svg/product/WarningCircleError.svg').default;

export function DropdownWithError({ value, options, disabled = false, placeholder, onChange, widthDrop, name, label, description, errorText, viewScreen }: SelectProps) {
    const [focusedOption, setFocusedOption] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
    const [isSelected, setIsSelected] = useState(false)
    const [selectedOption, setSelectedOption] = useState<string | undefined>()
    const [itemSelected, setItemSelected] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);

    const dropdownItemsRef = useRef<(HTMLLIElement | null)[]>([]);

    const carret = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M13 6L8 11L3 6" stroke="#818181" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    function isOptionSelected(option: SelectOption) {
        return option === value
    }

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLUListElement>) => {
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                if (focusedOption > 0) {
                    setFocusedOption(focusedOption - 1);
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (focusedOption < options.length - 1) {
                    setFocusedOption(focusedOption + 1);
                }
                break;
            case 'Enter':
                event.preventDefault();
                if (options[focusedOption]) {
                    setSelectedOption(options[focusedOption].label);
                    selectOption(options[focusedOption]);
                    setIsSelected(true);
                }
                break;
            default:
                break;
        }
    }, [focusedOption, options]);


    useEffect(() => {
        const option = document.querySelector(`li[data-focus="${focusedOption}"]`);
        option?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, [focusedOption]);


    const selectOption = (option: SelectOption) => {
        onChange(option);
        setSelectedOption(option.label ?? ''); // Set to empty string if label is undefined
    };


    const focusDropdown = () => {
        if (containerRef.current) {
            containerRef.current.click();
            setIsOpen(true);
        }
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <S.Container width={widthDrop}>
            {label && <label onClick={focusDropdown} htmlFor={name}>{label}</label>}
            <S.DropdownContainer
                viewScreen={viewScreen}
                errorText={errorText}
                id={name}
                widthDrop={widthDrop}
                isSelected={isSelected}
                itemSelected={itemSelected}
                disabled={disabled}
                isOpen={isOpen}
                hasValue={!!value}
                ref={containerRef}
                onClick={() =>
                    setIsOpen(prev => !prev)
                }
                tabIndex={0}
                onBlur={() => setIsSelected(false)}
            >
                <>
                    <span>{value ? value.label : selectedOption && selectedOption?.length > 0 ? selectedOption : placeholder}</span>
                    {carret}
                </>
                <ul ref={ulRef} tabIndex={0} className={`options ${isOpen ? "options show" : ""}`} onKeyDown={handleKeyDown}>
                    <ol>
                        {options.map((option, index) => {
                            return (
                                <li style={{ paddingLeft: 16 }}
                                    ref={(el) => (dropdownItemsRef.current[index] = el)}
                                    value={option.value}
                                    onClick={e => {
                                        selectOption(option)
                                        setIsSelected(true)
                                        setItemSelected(true)
                                        setSelectedOption(option.label)
                                    }}
                                    key={option.value}
                                    data-focus={index}
                                    className={`option ${isOptionSelected(option) ? "option selected" : ""} ${focusedOption === index ? "option focused" : ""}`}
                                >
                                    {option.label}
                                </li>
                            )
                        })}
                    </ol>
                </ul>
            </S.DropdownContainer>
            {errorText && (
                <S.ErrorContainer >
                    <img src={errorIconInput} alt="" />
                    <S.ErrorText >{errorText}</S.ErrorText>
                </S.ErrorContainer>
            )}
            {description && <S.Description>{description}</S.Description>}
        </S.Container>

    )
}