export const comments = [
    {
      comment: '“Recomento bastante! Tive uma experiência muito positiva, bem organizado, limpo e com profissionais muito simpáticos. Paraquem busca uma boa hospedagem em Curitiba, pode confiar”.',
      user: 'Joaldo',
      reference: 'Morador, pelo airbnb',
    },
    {
      comment: '“Adorei a hospedagem e com certeza ficarei mais vezes! Fomos muito bem recebidos, lugar organizado e limpo, arredores com muita coisa pra fazer, em um bairro nobre de Curitiba! Recomendo”.',
      user: 'Maristela',
      reference: 'Moradora, pelo airbnb',
    },
    {
      comment: '"Ótima estadia! O cheirinho de limpeza ficou até o último dia. A vista é maravilhosa e o anfitrião responde as dúvidas prontamente. Pessoal da portaria também é bastante solícito e educado. Tudo muito bom! Voltarei assim que possível!”.',
      user: 'Viviane',
      reference: 'Moradora, pelo airbnb',
    }
  ]