import { useState } from 'react';
import * as S from './styles';
import { Button } from '../../components/Button';
import { Input, Inputs } from '../../components/Input';
import { HeaderAlt } from '../../components/HeaderAlt';
import fetchAxios from 'services/http';
import { useNavigate } from 'react-router-dom';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import useAlert from 'components/Notification';
import { AxiosError } from 'axios';
import { NewInput } from 'components/NewInput';
import { IMaskInput } from 'react-imask';
import { WhatsappButton } from 'components/WhatsappButton';

export const accountInfoSchema = z.object({
  // username: z.string().nonempty({ message: 'Digite o seu nome' }).regex(/^[a-zA-Z ]+$/, { message: 'Nome inválido' }),
  // surname: z.string().nonempty({ message: 'Digite o seu sobrenome' }).regex(/^[a-zA-Z ]+$/, { message: 'Nome inválido' }),
  // phone: z.string()
  //   .nonempty({ message: 'Digite seu número de telefone' })
  //   .refine((value) => {
  //     if (value.startsWith('55') && value.length < 13) {
  //       return false;
  //     }
  //     return true;
  //   }, { message: 'Telefone inválido' }),
  // email: z.string().nonempty({ message: 'Digite o seu email' }).email({ message: 'Email inválido' }),
  password: z
    .string()
    .nonempty({ message: 'Digite uma senha' })
    .min(8, { message: 'A senha deve conter no mínimo 8 caracteres.' })
    .refine((value) => /[a-zA-Z]/.test(value) && /\d/.test(value), {
      message: 'A senha deve conter no mínimo 8 caracteres, sendo pelo menos um número e uma letra.'
    })
    .refine((value) => /^\S+$/.test(value), {
      message: 'A senha não pode conter espaços.'
    }),
  identification: z.string()
    .nonempty({ message: 'O campo de identificação não pode estar vazio' })
    .refine(value => value.length >= 11 && value.length <= 14, {
      message: 'CPF ou CNPJ inválidos',
    }),
});

type FormData = z.infer<typeof accountInfoSchema>;

export const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    trigger,
    watch
  } = useForm<FormData>({
    resolver: zodResolver(accountInfoSchema)
  });
  const navigate = useNavigate();
  const { Alert, showAlert } = useAlert({ active: false, title: '', type: 'SUCCESS' });

  const [firstName, setFirstName] = useState<string>('');
  const [firstNameTextError, setFirstNameTextError] = useState<string>('');

  const [secondName, setSecondName] = useState<string>('');
  const [secondNameTextError, setSecondNameTextError] = useState<string>('');

  // const [registration, setRegistration] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [passwordTextError, setPasswordTextError] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');

  const [emailTextError, setEmailTextError] = useState<string>('');
  const [acceptEmail, setAcceptEmail] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>('');
  const [phoneTextError, setPhoneTextError] = useState<string>('');

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [acceptTermsTextError, setAcceptTermsTextError] = useState<string>('');

  const googleIcon = require('assets/svg/social-midias/RegisterGoogle.svg').default;
  const facebookIcon = require('assets/svg/social-midias/RegisterFacebook.svg').default;
  const emailIcon = require('assets/svg/social-midias/Email.svg').default;

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$/;
    return passwordRegex.test(password);
  };

  const previousPage = document.referrer;

  const handleRegister = async (data: FormData) => {
    let errors = false;

    if (firstName === '') {
      setFirstNameTextError('Digite o seu nome.');
      errors = true;
    } else {
      setFirstNameTextError('');
    }

    if (secondName === '') {
      setSecondNameTextError('Digite o seu sobrenome.');
      errors = true;
    } else {
      setSecondNameTextError('');
    }

    if (email === '') {
      setEmailTextError('Digite um e-mail válido. Tente usar assim: exemplo@email.com');
      errors = true;
    } else if (!email.includes('@')) {
      setEmailTextError('Esse e-mail é inválido. Tente usar assim: exemplo@email.com');
      errors = true;
    } else if (email === 'yogha@yogha.com.br') {
      setEmailTextError('Este e-mail já está sendo usado. Tente usar outro e-mail.');
      errors = true;
    } else {
      setEmailTextError('');
    }

    if (phone === '') {
      setPhoneTextError('Digite o seu telefone.');
      errors = true;
    } else {
      setPhoneTextError('');
    }

    if (!acceptTerms) {
      setAcceptTermsTextError('Aceite os termos e condições para continuar.');
      errors = true;
    } else {
      setAcceptTermsTextError('');
    }
    if (!errors) {
      try {
        const res = await fetchAxios.post('/users/register', {
          email: email,
          mobile_phone: '+' + phone,
          surname: secondName,
          password: data.password,
          name: firstName,
          accepted_email: acceptEmail,
          cpf: data.identification
        });
        if (res.status === 200 || res.statusText === 'OK') {
          if (previousPage.includes('/login')) {
            navigate(-2);
          } else {
            navigate(-1);
          }
        } else {
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (
          axiosError.response?.data &&
          typeof axiosError.response.data === 'object' &&
          'message' in axiosError.response.data &&
          axiosError.response.data.message === 'Email ja esta em uso'
        ) {
          showAlert({ active: true, title: 'Esse e-mail já está em uso, tente usar outro.', type: 'ERROR' });
        } else {
          //@ts-ignore
          showAlert({
            active: true,
            //@ts-ignore
            title: `${axiosError.response.data.message} - ${axiosError.response.data.email}`,
            type: 'ERROR'
          });
        }
      }
    }
  };

  return (
    <S.Container>
      <HeaderAlt />
      <S.Content>
        <S.Header>
          <S.Title>Criar uma conta Yogha</S.Title>
          <S.SubTitle>Cadastre-se e conheça um novo jeito de viver!</S.SubTitle>
        </S.Header>
        {/* {!registration && (
          <>
            <S.SocialContainer>
            <S.RegisterButton>
              <img src={googleIcon} alt="" />
              <p>Criar usando sua conta do Google</p>
            </S.RegisterButton>
            <S.RegisterButton>
              <img src={facebookIcon} alt="" />
              <p>Criar usando sua conta do Facebook</p>
            </S.RegisterButton>
          </S.SocialContainer>
          <S.Separator>
            <p>ou</p>
          </S.Separator>
            <S.RegisterButton onClick={() => setRegistration(true)}>
              <img src={emailIcon} alt="" />
              <p>Criar usando um E-mail</p>
            </S.RegisterButton>

            <S.Footer>
              <S.FooterText>
                Já tem cadastro? <S.FooterLink to={'/login'}>Entrar</S.FooterLink>
              </S.FooterText>
            </S.Footer>
          </>
        )} */}
        <S.RegisterForms>
          <S.InputContainer>
            <S.NameContainer>
              <S.NameDiv>
                <Input
                  width={'100%'}
                  htmlFor="firstName"
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  placeholder="Seu primeiro nome"
                  title="Nome"
                  type="text"
                  value={firstName}
                  errorText={firstNameTextError}
                />
              </S.NameDiv>
              <S.Surname>
                <Input
                  width={'100%'}
                  htmlFor="secondName"
                  onChange={(e) => setSecondName(e.currentTarget.value)}
                  placeholder="Seu último nome"
                  title="Sobrenome"
                  type="text"
                  value={secondName}
                  errorText={secondNameTextError}
                />
              </S.Surname>
            </S.NameContainer>
            <S.PhoneContainer>
              <Inputs.Phone
                width={'100%'}
                htmlFor="tel"
                onChange={() => { }}
                onChangePhoneNumber={(phone: any) => setPhone(phone)}
                placeholder="Número de telefone"
                title="Telefone"
                type="tel"
                value={phone}
                errorText={phoneTextError}
              />
            </S.PhoneContainer>
            <S.EmailContainer>
              <Input
                width={'100%'}
                htmlFor="email"
                onChange={(e) => setEmail(e.currentTarget.value)}
                placeholder="Digite seu melhor e-mail"
                title="Email"
                type="text"
                value={email}
                errorText={emailTextError}
              />
              <S.LittleText>
                Vamos enviar confirmações de hospedagem e recibos para você por este e-mail.
              </S.LittleText>
            </S.EmailContainer>
            <S.PasswordContainer>
              <Controller
                name="identification"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NewInput width={'100%'} mask={''} name="identification" htmlFor="identification" placeholder="Digite o seu documento de identificação" title="Documento de identificação" type="text" errorText={errors.identification?.message}>
                    <IMaskInput
                      mask={[{ mask: "***.***.***-**" }, { mask: "**.***.***/****-**" }]}
                      radix="."
                      unmask={true} // true|false|'typed'
                      value={value}
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
                        if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onAccept={(value, mask) => {
                        onChange(value)
                      }}
                      placeholder="Digite o seu documento de identificação"
                      onBlur={() => trigger('identification')}
                    />
                  </NewInput>
                )
                }
              />
            </S.PasswordContainer>
            <S.PasswordContainer>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    showPassword
                    width={'100%'}
                    {...field}
                    htmlFor={field.name}
                    onChange={field.onChange}
                    placeholder="Digite uma senha forte"
                    title="Senha"
                    type="password"
                    value={field.value}
                    errorText={errors.password?.message}
                    onBlur={() => {
                      trigger('password');
                    }}
                  />
                )}
              />
            </S.PasswordContainer>
            <Inputs.Checkbox
              htmlFor="accept"
              type="checkbox"
              value={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
              placeholder=""
              title="Li e concordo com os"
              highlight="Termos de Uso e Política de Privacidade."
              errorText={acceptTermsTextError}
            />
            <Inputs.Checkbox
              htmlFor="accept"
              type="checkbox"
              value={acceptEmail}
              onChange={() => setAcceptEmail(!acceptEmail)}
              placeholder=""
              title="Aceito receber novidades da Yogha por email (opcional)."
            />
            <Button
              disabled={false}
              icon=""
              widthSize={'100%'}
              onClickButton={handleSubmit(handleRegister)}
              size="large"
              title="Concordar e continuar"
              type="primary"
            />
            <div>
              <S.FooterText>
                Já tem cadastro? <S.FooterLink to={'/login'}>Entrar</S.FooterLink>
              </S.FooterText>
            </div>
          </S.InputContainer>
        </S.RegisterForms>
      </S.Content>
      <Alert />
      <WhatsappButton />
    </S.Container>
  );
};
