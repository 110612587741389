import { StateCreator, create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { ReserveDetails } from './types';

export interface DataSessionPaymentProps {
    reserveDetails: ReserveDetails;
    weekValue: number,
    difference: number,
    nightValue: number,
    total: number,
    valueWeek: number,
    discount: boolean,
    condominiumValue: number,
    midStayValue: number,
    iptu: number,
    cleaningValue: number,
    pacoteYogha: number,
}

const initialState: DataSessionPaymentProps = {
    reserveDetails: {
        id: '',
        accommodationName: '',
        mainImage: '',
        address: {
            street: '',
            city: '',
            stateCode: '',
            area: '',
        },
    },
    weekValue: 0,
    difference: 0,
    nightValue: 0,
    total: 0,
    valueWeek: 0,
    discount: false,
    condominiumValue: 0,
    midStayValue: 0,
    iptu: 0,
    cleaningValue: 0,
    pacoteYogha: 0,
};

interface DataPaymentState {
    paymentData: DataSessionPaymentProps | null;
    setPaymentData: (data: DataSessionPaymentProps) => void;
    clearPaymentData: () => void;
    pixDiscountedTotalValue: (typePayment: string) => number;
    pixDiscountedNightValue: (typePayment: string) => number;
    pixDiscountedNightValueMidStay: (typePayment: string) => number;
    pixDiscountedTotalValueMidStay: (typePayment: string) => number;
    recoverNightByPixCode: () => number;
    recoverTotalValueByPixCode: () => number;
    recoverNightMidStayByPixCode: () => number;
    recoverTotalValueMidStayByPixCode: () => number;
}

// Definição do tipo para persistência
type SessiontStoragePersist = (
    config: StateCreator<DataPaymentState>,
    options: PersistOptions<DataPaymentState>
) => StateCreator<DataPaymentState>;

const usePaymentStore = create<DataPaymentState>(
    (persist as SessiontStoragePersist)(
        (set, get) => ({
            paymentData: initialState,
            setPaymentData: (data: DataSessionPaymentProps) => set({ paymentData: data }),
            clearPaymentData: () => set({ paymentData: null }),
            recoverTotalValueMidStayByPixCode: () => {
                const data = get().paymentData;
                if (data) {
                    return data.total / 0.9;
                }
                return 0;
            },
            recoverNightMidStayByPixCode: () => {
                const data = get().paymentData;
                if (data) {
                    const sumAllValues = data.total - (data.cleaningValue + data.condominiumValue + data.iptu + data.pacoteYogha);
                    return sumAllValues;
                }
                return 0;
            },
            recoverTotalValueByPixCode: () => {
                const data = get().paymentData;
                if (data) {
                    return (data.total / 0.9);
                }
                return 0;
            },
            recoverNightByPixCode: () => {
                const data = get().paymentData;
                if (data) {
                    const nightValue = data.total - data.cleaningValue;
                    return nightValue;
                }
                return 0;
            },
            pixDiscountedNightValue: (typePayment: string) => {
                const data = get().paymentData;
                if (data) {
                    if (typePayment === 'PIX') {
                        const discountTotal = data.total * 0.1;
                        const newNightValue = data.nightValue - discountTotal;
                        return newNightValue;
                    } else {
                        return data.nightValue;
                    }
                }
                return 0;
            },
            pixDiscountedNightValueMidStay: (typePayment: string) => {
                const data = get().paymentData;
                if (data) {
                    if (typePayment === 'PIX') {
                        const sumAllValues = data.midStayValue + data.condominiumValue + data.iptu + data.cleaningValue + data.pacoteYogha
                        const discountTotal = sumAllValues * 0.1;
                        const newValueMidStay = data.midStayValue - discountTotal;
                        return newValueMidStay;
                    } else {
                        return data.midStayValue;
                    }
                }
                return 0;
            },
            pixDiscountedTotalValue: (typePayment: string) => {
                const data = get().paymentData;
                if (data) {
                    if (typePayment === 'PIX') {
                        const discountByTotal = data.total * 0.1;
                        const newTotalValue = (data.nightValue - discountByTotal) + data.cleaningValue;
                        return newTotalValue;
                    } else {
                        return data.total;
                    }
                }
                return 0;
            },
            pixDiscountedTotalValueMidStay: (typePayment: string) => {
                const data = get().paymentData;
                if (data) {
                    if (typePayment === 'PIX') {
                        const sumAccommodationValues = data.midStayValue + data.condominiumValue + data.iptu + data.pacoteYogha
                        const sumAllValues = data.midStayValue + data.condominiumValue + data.iptu + data.cleaningValue + data.pacoteYogha
                        const discountTotal = sumAllValues * 0.1;
                        const total = (sumAccommodationValues - discountTotal) + data.cleaningValue;
                        return total;
                    } else {
                        return data.total;
                    }
                }
                return 0;
            },
        }),
        {
            name: 'payment-storage', // Nome da chave em sessionStorage
            getStorage: () => sessionStorage, // Utilizando sessionStorage
        }
    )
);

export default usePaymentStore;
