import { Button } from 'components/Button';
import * as S from './styles';
import { Link } from 'react-router-dom';
import { SkeletonPayment } from '../../components/SkeltonPayment';
import { usePixCode } from './usePixCode';

export const PixCode = () => {

    const { copy, handleCopyInlineCode, inlineCode, isFetching, pixIcon, qrCode, validTimePix, warningIcon } = usePixCode()

    return (
        <S.ContainerPixCode>
            {isFetching ? (
                <SkeletonPayment />
            ) : (
                <>
                    <S.Header>
                        <span>Forma de pagamento escolhido</span>
                        <S.InputPayment>
                            <S.InputRow>
                                <img src={pixIcon} alt="" />
                                <p>PIX</p>
                            </S.InputRow>
                            <Link to={'/payment'} style={{ alignSelf: 'flex-end', textDecoration: 'none' }}>
                                <S.ButtonContainer>
                                    <Button onClickButton={() => { }} size='small' title='Alterar' type='secondary' heightSize={"30px"} />
                                </S.ButtonContainer>
                            </Link>
                        </S.InputPayment>
                    </S.Header>
                    <S.ContainerInfo>
                        <h3>Escaneie o código abaixo para pagar</h3>
                        <div>
                            <S.RowInfo>
                                <p>1.</p><p>Acesse seu Internet Banking ou app de pagamentos.</p>
                            </S.RowInfo>
                            <S.RowInfo>
                                <p>2.</p><p>Escolha pagar via Pix.</p>
                            </S.RowInfo>
                            <S.RowInfo>
                                <p>3.</p><p>Escaneie o seguinte código:</p>
                            </S.RowInfo>
                        </div>
                    </S.ContainerInfo>
                    <S.PixCodeContainer>
                        <S.QrCodeImageContainer>
                            {qrCode !== null && <img src={`data:image/jpeg;base64,${qrCode}`} alt="" />}
                        </S.QrCodeImageContainer>
                    </S.PixCodeContainer>
                    <S.WarningContainer>
                        <strong>Fique atento!</strong>
                        <div>
                            {warningIcon}
                            <p>
                                Este código PIX irá expirar em: {validTimePix}.
                                <br />
                                Após a expiração, a sua pré-reserva será cancelada automaticamente.
                            </p>
                        </div>
                    </S.WarningContainer>
                    <S.Footer>
                        <S.Header>
                            <span>Ou copie este Código QR para fazer o pagamento</span>
                            <S.InputPayment>
                                <S.InlineCode>
                                    <h5>{inlineCode}</h5>
                                </S.InlineCode>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <S.ButtonContainer>
                                        <Button onClickButton={handleCopyInlineCode} size='small' title={copy} type='secondary' heightSize={"20px"} widthSize={'100%'} />
                                    </S.ButtonContainer>
                                </div>
                            </S.InputPayment>
                        </S.Header>
                    </S.Footer>
                </>
            )}
        </S.ContainerPixCode>


    );
}