import { useEffect, useMemo, useState } from 'react'
import { Icons } from 'components/Icons';
import { Inputs } from "components/Input";
import { OptionSelect } from "components/OptionSelect";
import * as S from "./styles";
import { useAnyProvider } from 'hooks/Context';
import { useSessionStorage } from 'context/sessionStorage';
export interface AmenitiesType {
    id: string;
    name: string;
    check: boolean;
    type?: string;
}
interface FilterProps {
    onClick: () => void;
    amenitiesSelected: (amenitiesIds: string[]) => void;
}

export const Filter = ({ onClick, amenitiesSelected }: FilterProps) => {

    const { amenities, setAmenities, setFilters, filters } = useAnyProvider()

    const { saveToSessionStorage, getFromSessionStorage } = useSessionStorage()

    // const [petFriendly, setPetFriendly] = useState(petFriendlyButton ? petFriendlyButton : 0)
    const [selectAmenities, setSelectAmenities] = useState<string[]>([])

    const addFilterSelect = (id: AmenitiesType['id']) => {
        const amenitiesExists = selectAmenities.filter(item => item === id)

        if (amenitiesExists.length > 0) {
            const removeAmenities = selectAmenities.filter(item => item !== id)
            setSelectAmenities(removeAmenities)
            const newAmenitieCheck = amenities.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        check: false
                    }
                } else {
                    return item
                }
            })
            setAmenities(newAmenitieCheck)
        } else {
            const newAmenitieCheck = amenities.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        check: true
                    }
                } else {
                    return item
                }
            })
            setAmenities(newAmenitieCheck)
            setSelectAmenities(prev => [...prev, id])
        }

    }

    const memoizedAmenities = useMemo(() => amenities, [JSON.stringify(amenities)]);

    useEffect(() => {
        if (memoizedAmenities.length > 0) {
            saveToSessionStorage('amenities', memoizedAmenities);
            console.log('salvou aqui')
        }
    }, [memoizedAmenities]);

    useEffect(() => {
        const data: AmenitiesType[] = getFromSessionStorage('amenities')
        if (data.length > 0) {
            const filterActives = data
                .filter((item) => item.check)
                .map((item) => item.id);

            setSelectAmenities(filterActives);
        }
    }, [])


    // const handleFilterPetFriendly = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = Number(e.currentTarget.value);
    //     setPetFriendly(value);
    //     setPetFriendlyButton(Number(e.currentTarget.value))
    //     if (value === 2) {
    //         const updatedAmenities = amenities.map((item) => {
    //             if (item.id === '2M6fwAAWs9708iuSv4AhOyOV') {
    //                 return {
    //                     ...item,
    //                     check: true
    //                 };
    //             }
    //             return item;
    //         });
    //         setAmenities(updatedAmenities);
    //     } else {
    //         const updatedAmenities = amenities.map((item) => {
    //             if (item.id === '2M6fwAAWs9708iuSv4AhOyOV') {
    //                 return {
    //                     ...item,
    //                     check: false
    //                 };
    //             }
    //             return item;
    //         });
    //         setAmenities(updatedAmenities);
    //     }
    // };


    const closedFilter = require('assets/svg/closedFilter.svg').default

    const arr = [{ label: 'Qualquer um', value: 0, indice: 0, key: 10 },
    { label: '1', value: 1, indice: 1, key: 11 },
    { label: '2', value: 2, indice: 2, key: 14 },
    { label: '3', value: 3, indice: 3, key: 12 },
    { label: '4', value: 4, indice: 4, key: 13 },
    ]
    const arrBeds = [{ label: 'Qualquer um', value: 0, indice: 0, key: 15 },
    { label: '1', value: 1, indice: 1, key: 16 },
    { label: '2', value: 2, indice: 2, key: 19 },
    { label: '3', value: 3, indice: 3, key: 17 },
    { label: '4', value: 4, indice: 4, key: 18 },
    ]
    const arrBath = [{ label: 'Qualquer um', value: 0, indice: 0, key: 20 },
    { label: '1', value: 1, indice: 1, key: 21 },
    { label: '2', value: 2, indice: 2, key: 24 },
    { label: '3', value: 3, indice: 3, key: 22 },
    { label: '4', value: 4, indice: 4, key: 23 },
    ]

    // const arrpet = [
    //     { label: 'Sim', value: 2, indice: 2 },
    //     { label: 'Não', value: 1, indice: 1 },
    //     { label: 'Tanto faz', value: 0, indice: 0 },
    // ]

    const arrayAmenitiesComodities = [
        'Wifi',
        'Banheira e hidromassagem',
        'Água quente',
        'Banheiro acessível',
        'Ar-condicionado',
        'Fogão',
        'Aquecimento',
        'Forno',
        'Geladeira',
        'Air fryer',
        'Cozinha compacta',
        'Liquidificador',
        'Utensílios de cozinha',
        'Cafeteira',
        'Smart TV',
        'Chaleira',
        'TV a cabo',
        'Frigobar',
        'Sofá',
        'Lava-louças',
        'Churrasqueira',
        'Máquina de lavar',
        'Guarda-roupas',
        'Varanda',
        'Ferro de passar',
        'Portas amplas na acomodação',
        'Secador de cabelo',
        'Secadora de roupas',

    ]

    const handleCleanFilter = () => {
        const newAmenities = amenities.map(item => ({
            ...item,
            check: false
        }));
        setAmenities(newAmenities);
        // setPetFriendly(0)
        // setPetFriendlyButton(0)
        setFilters({
            ...filters,
            bathroom: 0,
            bedroom: 0,
            beds: 0
        })
        setSelectAmenities([]);
    };


    const arrayAmenitiesCondominium = [
        'Recepção 24 horas',
        'Espaço pet',
        'Academia',
        'Churrasqueira',
        'Lavanderia coletiva',
        'Salão de jogos',
        'Elevador',
        'Piscina interna',
        'Restaurante',
        'Piscina ao ar livre',
        'Coworking',
        'Piscina aquecida',
        'Solarium',
        'Sauna',
        'Garagem',
        'Spa',
        'Minimarket',
        'Aluguel de bicicletas',
        'Vending machine',
        'Brinquedoteca',
    ]

    function escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& significa a string inteira correspondente
    }

    function replaceNameFilters(str: string): string {
        const replacements = {
            'Louças e Talheres': 'Utensílios de cozinha',
            'Máquina de venda automática (lanches)': 'Vending machine',
            'Máquina de Café': 'Cafeteira',
            'TV smart': 'Smart TV',
            'Wi-fi': 'Wifi',
            'Banheira e Hidromassagem Separadas': 'Banheira e hidromassagem',
            'Mini Mercado': 'Minimarket',
            'AirFryer': 'Air fryer',
            'Guarda-roupa ou Armário': 'Guarda-roupas',
            'Ar Condicionado': 'Ar-condicionado',
            'Cozinha/Cozinha Compacta': 'Cozinha compacta',
            'Portas amplas': 'Portas amplas na acomodação',
            'Chaleira elétrica': 'Chaleira',
            'Secadora': 'Secadora de roupas',
            'Máquina de Lavar Roupa': 'Máquina de lavar',
            'Piscina aquecida (Compartilhada)': 'Piscina aquecida',
            'Piscina ao ar livre (Compartilhada)': 'Piscina ao ar livre',
            'Academia (área comum)': 'Academia',
            'Churrasqueira / Área para piquenique': 'Churrasqueira',
            'Lavanderia': 'Lavanderia coletiva',
            'Piscina interna (Compartilhada)': 'Piscina interna',
            'Solário': 'Solarium',
            'Aquecimento Central': 'Aquecimento'            // Adicione mais substituições conforme necessário
        };

        for (const [key, value] of Object.entries(replacements)) {
            str = str.split(new RegExp(escapeRegExp(key), 'gi')).join(value);
        }

        return str;
    }

    const filteredAmenitiesComodites = amenities && amenities
        .filter((amenitie) => amenitie.type === 'listing')
        .map((item) => ({
            ...item,
            name: replaceNameFilters(item.name)
        }))
        .filter((item) => arrayAmenitiesComodities.some(comodity => comodity.toLowerCase() === item.name.toLowerCase()));

    const filteredAmenitiesCondominium = amenities && amenities
        .filter((amenitie) => amenitie.type === 'property')
        .map((item) => ({
            ...item,
            name: replaceNameFilters(item.name)
        }))
        .filter((item) => arrayAmenitiesCondominium.some(comodity => comodity.toLowerCase() === item.name.toLowerCase()));

    return (
        <S.Container>
            <S.Modal>
                <S.TitleFilter> <p>Filtros:</p> <button onClick={onClick}> <Icons icon={closedFilter} width={15} height={15} /> </button></S.TitleFilter>
                <S.ModalContent>
                    <S.RowModal>
                        <S.SubTitleFilter> Dormitórios </S.SubTitleFilter>
                        <S.RowOption>
                            {arr.map((item) => {
                                return (
                                    <OptionSelect id={item.indice} key={item.label} htmlFor={item.label} selected={item.indice === filters.bedroom ? true : false} value={item.value} title={item.label} onClick={(e: any) => setFilters({
                                        ...filters,
                                        bedroom: Number(e.currentTarget.value)
                                    })} />
                                )
                            })}
                        </S.RowOption>
                    </S.RowModal>
                    <S.RowModal>
                        <S.SubTitleFilter> Camas </S.SubTitleFilter>
                        <S.RowOption>
                            {arrBeds.map((item) => {
                                return (
                                    <OptionSelect id={item.indice} key={item.indice} htmlFor={String(item.key)} selected={item.indice === filters.beds ? true : false} value={item.value} title={item.label} onClick={(e: any) => setFilters({ ...filters, beds: Number(e.currentTarget.value) })} />
                                )
                            })}
                        </S.RowOption>
                    </S.RowModal>
                    <S.RowModal>
                        <S.SubTitleFilter> Banheiros </S.SubTitleFilter>
                        <S.RowOption>
                            {arrBath.map((item) => {
                                return (
                                    <OptionSelect id={item.indice} key={item.indice} htmlFor={String(item.key)} selected={item.indice === filters.bathroom ? true : false} value={item.value} title={item.label} onClick={(e: any) => setFilters({ ...filters, bathroom: Number(e.currentTarget.value) })} />
                                )
                            })}
                        </S.RowOption>
                    </S.RowModal>
                    {/* <S.RowModal>
                        <S.SubTitleFilter> Pet friendly </S.SubTitleFilter>
                        <S.RowOption>
                            {arrpet.map((item) => {
                                return (
                                    <OptionSelect id={item.indice} key={item.indice} htmlFor={item.label} selected={item.indice === Number(petFriendly) ? true : false} value={item.value} title={item.label} onClick={handleFilterPetFriendly} />
                                )
                            })}
                        </S.RowOption>
                    </S.RowModal> */}
                    <S.FilterAmenitiesTitle style={{ marginTop: 39 }}> Comodidades </S.FilterAmenitiesTitle>
                    <S.FilterRow>
                        <S.FilterColumn>
                            {
                                arrayAmenitiesComodities.map((comodity) => {
                                    const item = filteredAmenitiesComodites.find(item => item.name.toLowerCase() === comodity.toLowerCase());
                                    return item && (
                                        <Inputs.Checkbox key={item.name} htmlFor={item.name} type="checkbox" onChange={() => { addFilterSelect(item.id) }} placeholder='' title={item.name} value={item.check} />
                                    );
                                })
                            }
                        </S.FilterColumn>
                    </S.FilterRow>
                    <S.Separator></S.Separator>
                    <S.FilterAmenitiesTitle> Condomínio </S.FilterAmenitiesTitle>
                    <S.FilterRow>
                        <S.FilterColumn>
                            {
                                arrayAmenitiesCondominium.map((comodity) => {
                                    const item = filteredAmenitiesCondominium.find(item => item.name.toLowerCase() === comodity.toLowerCase());
                                    return item && (
                                        <Inputs.Checkbox key={item.name} htmlFor={item.name} type="checkbox" onChange={() => { addFilterSelect(item.id) }} placeholder='' title={item.name} value={item.check} />
                                    );
                                })
                            }
                        </S.FilterColumn>
                    </S.FilterRow>
                </S.ModalContent>
                <S.Divisor />
                <S.ButtonRow>
                    <S.ButtonClean onClick={handleCleanFilter}> Limpar filtros </S.ButtonClean>                <S.ButtonFooter onClick={() => amenitiesSelected(selectAmenities)}>Ver resultados</S.ButtonFooter>
                </S.ButtonRow>
            </S.Modal>
        </S.Container>
    );
};