import { useLocation } from 'react-router-dom'
import * as S from './styles'

export const WhatsappButton = () => {

    const { pathname } = useLocation()

    const routes = [
        'payment',
        'login',
        'forgotpassword',
        'properties',
        'signup',
    ]

    const noHasMenuBar: boolean = routes.some(route => pathname.includes(route));


    return (
        <S.Container noHasMenuBar={noHasMenuBar}>
            <a href="https://api.whatsapp.com/send?phone=5541996941949&text=Ol%C3%A1,%20vim%20pelo%20site.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es!" target="_blank" rel="noreferrer">
                <img src="https://img.icons8.com/color/48/000000/whatsapp--v1.png" alt="whatsapp" />
            </a>
        </S.Container>
    )
}