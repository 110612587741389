import * as S from './styles'
import dayjs from 'dayjs'
import { CustomStayCardProps } from './types'
import { usePaymentCard } from 'pages/Payment/hooks/usePaymentCard'
import { useRenderMistay } from './useRenderMidstay'
import { useRenderShortStay } from './useRenderShortStay'
import { useState } from 'react'
import { ProductCard } from 'components/ProductCard'


export const PaymentCard = ({ discount, accommodation, }: CustomStayCardProps) => {

    const { mainImage, accommodationName, dataPayment, address, typePayment, date, nights, expensesData, hasCodePix, discountDifference, discountValue, difference, weekValue, totalValue } = usePaymentCard({ accommodation })

    const { RenderMidStayCard } = useRenderMistay({ discount, nights, dataPayment, expensesData, discountDifference, discountValue, hasCodePix, typePayment, totalValue })
    const { RenderShortStayCard } = useRenderShortStay({ discount, nights, dataPayment, expensesData, discountDifference, discountValue, hasCodePix, typePayment, totalValue, difference, weekValue })

    const [openCupomModal, setOpenCupomModal] = useState<boolean>(false)
    const [openUnderstandValues, setOpenUnderstandValues] = useState<boolean>(false)

    return (
        <S.Container>
            <S.TitlePayment>Conclua sua reserva</S.TitlePayment>
            <S.TitleCard>Resumo da sua hospedagem</S.TitleCard>
            <S.Header>
                <S.ImageAccommodation>
                    <img src={mainImage === '' ? dataPayment?.reserveDetails.mainImage : mainImage} alt="" />
                </S.ImageAccommodation>
                <S.AccommodationInfo>
                    <S.Subtitle>{accommodationName === '' ? dataPayment?.reserveDetails.accommodationName : accommodationName}</S.Subtitle>
                    <p>{address.street === '' ? dataPayment?.reserveDetails.address?.street : address.street} -{address.city === '' ? dataPayment?.reserveDetails.address?.city : address.city}, {address.stateCode === '' ? dataPayment?.reserveDetails.address?.stateCode : address.street}</p>
                    <S.LabelContainer>
                        <div>
                            <span>Entrada </span>
                            <p>{!!date && date[0] && dayjs(date[0]).format('DD/MM/YYYY')}</p>
                        </div>
                        <div>
                            <span>Saída </span>
                            <p> {!!date && date[1] && dayjs(date[1]).format('DD/MM/YYYY')}</p>
                        </div>

                    </S.LabelContainer>
                </S.AccommodationInfo>
            </S.Header>
            <S.Content>
                {/* {MidStay} */}
                {difference >= 27 && <RenderMidStayCard />}
                {/* {ShortStay} */}
                {difference < 27 && <RenderShortStayCard />}
            </S.Content>
        </S.Container>
    )
}