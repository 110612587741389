import { ChangeEvent, useCallback, useEffect, useRef, useState, } from "react"
import * as S from "./styles"
import { useAnyProvider } from "hooks/Context"
import { useAutoCompletInput } from "./useAutoCompletInput"

export type SelectOption = {
    label: string,
    value: string | number,
}

export type SelectPropsWithFilterAndMap = {
    filter(arg0: (item: any) => boolean): SelectOption,
    map(arg0: (option: any) => JSX.Element): JSX.Element
} & SelectOption

type SelectProps = {
    disabled?: boolean
    options: SelectOption[]
    value?: SelectOption
    placeholder?: string
    onChange: (value: SelectOption | undefined) => void
    widthDrop: number | string
    inputSearchValue?: any
    hasResult?: any
    expanded?: boolean
    shouldFocus?: boolean
    setShouldFocus?: (arg0: boolean) => void
    getProperties?: (ags: number) => Promise<void>;
    isOpen: boolean
    setIsOpen: (arg0: boolean) => void
    setInputTypeSearchValue?: any
    setInputSearchValue?: any
}

const mapPin = require("../../assets/svg/MapPin.svg").default

export function AutoCompleteInput({ value, options, disabled = false, placeholder, onChange, widthDrop, inputSearchValue, setInputTypeSearchValue, hasResult = null, expanded, shouldFocus, setShouldFocus, getProperties, setIsOpen, isOpen, setInputSearchValue }: SelectProps) {
    const [focusedOption, setFocusedOption] = useState(0);
    const [isSelected, setIsSelected] = useState(false)
    const [itemSelected, setItemSelected] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const childRef = useRef<HTMLLIElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);

    const glass = require("assets/svg/BlackMagnifyingGlass.svg").default

    const { isLoading, noResultsFound, results, pressEnterSelectOption } = useAutoCompletInput({ inputRef: inputRef, getProperties, isOpen, inputSearchValue, shouldFocus: shouldFocus, setShouldFocus: setShouldFocus, setIsOpen, setInputTypeSearchValue, setInputSearchValue, })


    function isOptionSelected(option: SelectOption) {
        return option === value
    }

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLUListElement>) => {
        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                if (focusedOption > 0) {
                    setFocusedOption(focusedOption - 1);
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (focusedOption < options.length - 1) {
                    setFocusedOption(focusedOption + 1);
                }
                break;
            case 'Enter':
                event.preventDefault();
                if (options[focusedOption]) {
                    setInputSearchValue(options[focusedOption].label);
                    selectOption(options[focusedOption]);
                    setIsSelected(true);
                }
                break;
            default:
                break;
        }
    }, [focusedOption, options, setInputSearchValue]);

    useEffect(() => {
        const option = document.querySelector(`li[data-focus="${focusedOption}"]`);
        option?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, [focusedOption]);

    const resultLI = (hasResult: any) => {
        if (hasResult !== null)
            return (<S.ResultLi> <img src={mapPin} style={{ marginTop: '-3px', width: 24, height: 24, rotate: "360deg" }} alt="" /> Resultado</S.ResultLi>)
    }

    let inputDropdownList = null;

    if (isLoading) {
        inputDropdownList = <li style={{ fontSize: 14 }}>Carregando...</li>;
    } else if (inputSearchValue?.length <= 0) {
        inputDropdownList = <li style={{ display: 'flex', fontSize: 14, paddingLeft: 18, alignItems: 'center', height: 'fit-content' }}> <img style={{
            width: 24,
            height: 24,
            marginRight: 12,
            rotate: "0deg",
            marginTop: '-3px'
        }} src={glass} alt="" />  Digite sua busca</li>;
    } else if (noResultsFound) {
        inputDropdownList = <li style={{ fontSize: 14 }}>Não encontramos o que busca</li>;
    } else {
        inputDropdownList = [resultLI(hasResult), results.map((option: any, index: number) => {
            return (
                <li
                    ref={childRef}
                    key={index}
                    className={isOptionSelected(option.name) ? "selected" : ""}
                    onClick={(e) => {
                        setInputSearchValue(e.currentTarget.innerHTML)
                        setInputTypeSearchValue(option.type)
                        selectOption(option.name);
                        setIsSelected(true);
                        inputRef.current?.blur()
                        setIsOpen(false)
                    }}
                >
                    {option.name}
                </li>
            );
        })]
    }

    const selectOption = (option: SelectOption) => {
        if (option !== value) onChange(option)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (inputSearchValue?.length > 0) {
            setIsOpen(true)
        }
        setInputSearchValue(e.target.value)
    }


    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <S.DropdownContainer widthDrop={widthDrop}
            isSelected={isSelected}
            itemSelected={itemSelected}
            disabled={disabled}
            isOpen={isOpen}
            ref={containerRef}
            onClick={() => {
                if (expanded === false)
                    setIsOpen(!isOpen)
            }}
            tabIndex={0}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsSelected(false)}
        >
            <input ref={inputRef} value={inputSearchValue} onKeyDown={pressEnterSelectOption
            } onFocus={() => setIsSelected(true)} onBlur={() => {
                if (inputSearchValue?.length === 0)
                    setIsSelected(false)
            }} placeholder={placeholder} onChange={handleChange} />
            <ul ref={ulRef} tabIndex={0} className={`options ${isOpen ? "options show" : ""}`} onKeyDown={handleKeyDown}>
                {inputDropdownList}
            </ul>
        </S.DropdownContainer>
    )
}
