// CalendarComponent.tsx
import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { CalendarIcon } from 'assets/svg/calendar/CalendarIcon';
import { Button } from 'components/Button';
import useAlert from 'components/Notification';
import { useScreenWidth } from 'utils/useScreenWidth';
import { useCalendar } from './useCalendar';
import { PRIMARY_COLORS } from 'constants/styleConstants';


interface CalendarComponentProps {
    blockedDates?: string[];
    date: any;
    setDate: any;
    inputDatesWidth?: string;
    noHasBorder?: boolean;
}

const leftCaret = require('../../assets/svg/calendar/caretLeft.svg').default;
const rightCaret = require('../../assets/svg/calendar/caretRight.svg').default;
const closerIcon = require('../../assets/svg/calendar/CloseIcon.svg').default;

export const CalendarComponent: React.FC<CalendarComponentProps> = ({ blockedDates, setDate, date, inputDatesWidth, noHasBorder }) => {
    const { handleOpenCalendar, openCalendar, handleCloseCalendar, calendarRef, inputsRef, footerRef, handleNextMonth, handlePrevMonth, currentMonth, currentYear, nameOfMonth, nextMonth, nextYear, handleClearDates, selectedEndDate, selectedStartDate, renderMonth } = useCalendar({ blockedDates, date, setDate });

    const breakpoint = useScreenWidth();

    const daysOnWeekend = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const { Alert } = useAlert({ active: false, title: '', type: 'SUCCESS' })


    const getDaysInMonthWithAlignment = (year: number, month: number): Date[] => {
        const firstDayOfMonth = new Date(year, month, 1).getDay(); // Dia da semana do primeiro dia do mês (0=Domingo, 6=Sábado)
        const daysInMonth = new Date(year, month + 1, 0).getDate(); // Número de dias no mês

        // Calcula os dias do mês anterior necessários para preencher a primeira semana.
        const prevMonthPaddingDays = Array(firstDayOfMonth).fill(null);

        // Dias do mês atual.
        const currentMonthDays = Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));

        return [...prevMonthPaddingDays, ...currentMonthDays];
    };

    const daysCurrentMonth = getDaysInMonthWithAlignment(currentYear, currentMonth);
    const daysNextMonth = getDaysInMonthWithAlignment(nextYear, nextMonth);

    const renderThirteenMonths = () => {
        let actualMonth = new Date().getMonth();
        const months = [];
        for (let i = 0; i < 13; i++) {
            if (actualMonth + i <= 11) {
                months.push(new Date().getMonth() + i);
            } else {
                months.push(new Date().getMonth() + i - 12);
            }

        }
        return months;
    }

    const thirteenMonths = renderThirteenMonths();

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <S.ContainerDatePicker openCalendar={openCalendar}>
            <S.InputContainer noHasBorder={noHasBorder} isCalendarOpen={openCalendar} width={inputDatesWidth} ref={inputsRef}>
                <S.CalendarInput ref={inputsRef} onClick={handleOpenCalendar} hasDate={!!selectedStartDate}>
                    <input readOnly placeholder="Entrada" type="text" value={selectedStartDate ? selectedStartDate.toLocaleDateString() : ''} />
                    <CalendarIcon color={openCalendar && !selectedStartDate ? PRIMARY_COLORS.MEDIUM : selectedStartDate ? '#161616' : undefined} />
                </S.CalendarInput>
                <S.CalendarInput ref={inputsRef} onClick={handleOpenCalendar} hasDate={!!selectedEndDate}>
                    <input readOnly placeholder="Saída" type="text" value={selectedEndDate ? selectedEndDate.toLocaleDateString() : ''} />
                    <CalendarIcon color={selectedStartDate && !selectedEndDate ? PRIMARY_COLORS.MEDIUM : selectedEndDate ? '#161616' : undefined} />
                </S.CalendarInput>

            </S.InputContainer>
            {openCalendar && !breakpoint ? (
                <S.ContainerCalendars>
                    <S.Calendars ref={calendarRef}>
                        <S.LeftCalendarContainer>
                            <S.CalendarHeader>
                                {currentMonth !== new Date().getMonth() && <button onClick={handlePrevMonth}><img src={leftCaret} alt="" /></button>}
                                <div>
                                    {nameOfMonth(currentMonth)} - {currentYear}
                                </div>
                            </S.CalendarHeader>
                            <S.DaysWeekContainer>
                                {daysOnWeekend.map((day, index) => {
                                    return (
                                        <p key={index}>
                                            {day}
                                        </p>
                                    )
                                }
                                )}
                                {renderMonth(daysCurrentMonth, currentMonth, currentYear)}
                            </S.DaysWeekContainer>
                        </S.LeftCalendarContainer>
                        <S.RightCalendarContainer>
                            <S.CalendarHeader>
                                <div>
                                    {nameOfMonth(nextMonth)} - {nextYear}
                                </div>
                                {nextMonth >= new Date().getMonth() && currentYear >= new Date().getFullYear() + 1 ? null : <button onClick={handleNextMonth}>
                                    <img src={rightCaret} alt="" />
                                </button>}
                            </S.CalendarHeader>
                            <S.DaysWeekContainer>
                                {daysOnWeekend.map((day, index) => {
                                    return (
                                        <p key={index}>
                                            {day}
                                        </p>
                                    )
                                }
                                )}
                                {renderMonth(daysNextMonth, nextMonth, nextYear)}
                            </S.DaysWeekContainer>
                        </S.RightCalendarContainer>
                    </S.Calendars>
                    <S.FooterCalendar ref={footerRef} >
                        <Button onClickButton={handleClearDates} size='medium' title='Limpar datas' type='secondary' />
                    </S.FooterCalendar>
                </S.ContainerCalendars>
            ) : breakpoint && openCalendar ? (
                <S.MobileViewCalendar>
                    <S.MobileCalendarHeader>
                        <p>Selecione as datas</p>
                        <S.CloseButton onClick={handleCloseCalendar}>
                            <img src={closerIcon} alt="" />
                        </S.CloseButton>
                    </S.MobileCalendarHeader>
                    <S.MobileCalerdars>
                        {thirteenMonths.map((month, index) => {
                            let dayCurrentThirteenMonth: Date[] = [];
                            const currentMonth = new Date().getMonth();
                            const currentYear = new Date().getFullYear();
                            let thisCurrentYear = new Date().getFullYear();
                            if (currentMonth >= month && index !== 0) {
                                thisCurrentYear = currentYear + 1;
                                dayCurrentThirteenMonth = getDaysInMonthWithAlignment(currentYear + 1, month);
                            } else {
                                dayCurrentThirteenMonth = getDaysInMonthWithAlignment(currentYear, month);
                            }


                            return (
                                <S.CalendarContainerMobile key={month + index}>
                                    <S.CalendarHeader>
                                        <div>
                                            {nameOfMonth(month)} - {thisCurrentYear}
                                        </div>
                                    </S.CalendarHeader>
                                    <S.DaysWeekContainer>
                                        {daysOnWeekend.map((day, month) => {
                                            return (
                                                <p key={index}>
                                                    {day}
                                                </p>
                                            )
                                        }
                                        )}
                                        {renderMonth(dayCurrentThirteenMonth, month, thisCurrentYear)}
                                    </S.DaysWeekContainer>
                                </S.CalendarContainerMobile>
                            )
                        }
                        )}
                    </S.MobileCalerdars>
                    <S.MobileFooterCalendar>
                        <Button onClickButton={handleClearDates} size='medium' title='Limpar datas' type='secondary' />
                        <Button onClickButton={handleCloseCalendar} size='medium' title='Confirmar' type='primary' />
                    </S.MobileFooterCalendar>
                </S.MobileViewCalendar>
            ) : (null)
            }
            <Alert />
        </S.ContainerDatePicker>
    );
};


