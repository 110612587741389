import { createContext, useContext, useState } from 'react';


type AmenitiesType = {
  id: string;
  name: string;
  check: boolean;
  type?: string;
}

type FilterType = {
  guest: number,
  beds: number,
  bathroom: number,
  bedroom: number,
  // petFriendly:
}

type ReservationDetailsType = {
  accommodation_code: string | number,
  accommodation_id: number,
  ref_stays: string,
  hostingValue: number,
  amount: string,
  city: string,
  code: number,
  reserveServices: Service[],
  nameBuilding?: string,
  nameAcc?: string,
  user?: {
    name: string,
    surname: string,
    email: string,
    phone: string,
    dni: string,
  }
}

export type Service = {
  amount: number,
  service_id: string | number,
  value: number
}


type AnyContextType = {
  anyValue: string;
  searchValue: string;
  searchType: string;
  useManager: string;
  guest: number;
  date: string[] | null | Date[];
  amenities: AmenitiesType[];
  reservationDetails: ReservationDetailsType;
  filters: FilterType;
  autoCompletSearch: string;
  setFilters: (newState: FilterType) => void
  setDate: (newState: string[] | null | Date[]) => void;
  setGuest: (newState: number) => void;
  setAnyValue: (newState: string) => void;
  setUseManager: (newState: string) => void;
  setAutoCompletSearch: (newState: string) => void;
  setSearchValue: (newState: string) => void;
  setSearchType: (newState: string) => void;
  setAmenities: (newState: AmenitiesType[]) => void;
  setReservationDetails: (newState: ReservationDetailsType) => void;
  petFriendlyButton: number,
  setPetFriendlyButton: (newState: number) => void
};

const AnyContext = createContext<AnyContextType>({
  anyValue: '',
  setAnyValue: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  date: null,
  setDate: function (newState: string[] | null | Date[]): void {
    throw new Error('Function not implemented.');
  },
  searchValue: '',
  setSearchValue: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  useManager: '',
  setUseManager: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  guest: 1,
  setGuest: function (newState: any): void {
    throw new Error('Function not implemented.');
  },
  autoCompletSearch: '',
  setAutoCompletSearch: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
  reservationDetails: {
    accommodation_code: "",
    accommodation_id: 0,
    ref_stays: "",
    hostingValue: 0,
    amount: "",
    city: "",
    code: 0,
    reserveServices: [],
  },
  setReservationDetails: function (newState: ReservationDetailsType): void {
    throw new Error('Function not implemented.');
  },
  amenities: [],
  setAmenities: function (newState: AmenitiesType[]): void {
    throw new Error('Function not implemented.');
  },
  filters: {
    bathroom: 0,
    bedroom: 0,
    beds: 0,
    guest: 0,
  },
  setFilters: function (newState: FilterType): void {
    throw new Error('Function not implemented.');
  },
  petFriendlyButton: 0,
  setPetFriendlyButton(newState): void {
    throw new Error('Function not implemented.');
  },
  searchType: '',
  setSearchType: function (newState: string): void {
    throw new Error('Function not implemented.');
  },
});

const AnyProvider = ({ children }: any) => {
  const [anyValue, setAnyValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [useManager, setUseManager] = useState('');
  const [guest, setGuest] = useState<number>(1);
  const [date, setDate] = useState<string[] | null | Date[]>([]);


  const [reservationDetails, setReservationDetails] = useState<ReservationDetailsType>({
    accommodation_code: "",
    accommodation_id: 0,
    ref_stays: "",
    hostingValue: 0,
    amount: "",
    city: "",
    code: 0,
    reserveServices: [],
  });
  const [amenities, setAmenities] = useState<AmenitiesType[]>([]);
  const [filters, setFilters] = useState<FilterType>({
    bathroom: 0,
    bedroom: 0,
    beds: 0,
    guest: 0
  })
  const [petFriendlyButton, setPetFriendlyButton] = useState<number>(0)
  const [searchType, setSearchType] = useState<string>('');
  const [autoCompletSearch, setAutoCompletSearch] = useState<string>('');


  return (
    <AnyContext.Provider
      value={{
        anyValue,
        setAnyValue,
        searchValue,
        setSearchValue,
        useManager,
        setUseManager,
        date,
        setDate,
        guest,
        setGuest,
        reservationDetails,
        setReservationDetails,
        amenities,
        setAmenities,
        filters,
        setFilters,
        petFriendlyButton,
        setPetFriendlyButton,
        searchType,
        setSearchType,
        autoCompletSearch,
        setAutoCompletSearch
      }}
    >
      {children}
    </AnyContext.Provider>
  );
};

const useAnyProvider = () => {
  const context = useContext(AnyContext);
  return context;
};

export { useAnyProvider, AnyProvider };

// AnyProvider goes around the components
// import { useAnyProvider } from '/Context'
// const anyValue, setAnyValue = useAnyProvider();
