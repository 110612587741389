import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ContainerToogleValueFee = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    gap: 8px;

    padding: 6px 8px;

    border-radius: 10px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};

    p{
        white-space: nowrap;
        ${FONT.BODY.BODY_SMALL[500]}
    }

    @media (max-width: 850px) {
        width: 100%;
        justify-content: space-between;
    }
`;