import * as S from './styles';
import { Toggle } from '../../../../components/Toggle';
import { useSessionStorage } from 'context/sessionStorage';
import { useEffect } from 'react';
import { useScreenWidth } from 'utils/useScreenWidth';

type ToogleValueFeeProps = {
    name: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

export const ToogleValueFee = ({ name, value, onChange }: ToogleValueFeeProps) => {

    const { saveToSessionStorage, getFromSessionStorage, removeFromSessionStorage } = useSessionStorage();
    const breakpoint = useScreenWidth();

    const pathname = window.location.pathname;

    useEffect(() => {
        return () => {
            if (!breakpoint) {
                removeFromSessionStorage(name)
            }
        }
    }, [])


    useEffect(() => {
        saveToSessionStorage(name, value);
    }, [value])


    const handleOnChange = (value: boolean) => {

        onChange(value);
    }

    return (
        <S.ContainerToogleValueFee>
            <p>Mostrar preço final com taxas </p>
            <Toggle name={name} value={value} onChange={handleOnChange} />
        </S.ContainerToogleValueFee>
    )
}