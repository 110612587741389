import { ButtonGradient } from 'pages/Owner/components/ButtonGradient'
import * as S from './styles'
import { Footer } from 'components/Footer'
import { SliderCommentsFlexHousing } from 'components/SliderCommentsFlexHousing'
import { useEffect, useState } from 'react'
import { ImageCarousel } from 'components/ImageCarousel'
import fetchAxios from 'services/http'
import { useQuery } from 'react-query'
import { mapBuilding, mapGetProperties } from 'utils/formatterArray'
import { NavContainer } from 'components/Header/styles'
import { NavBar } from 'components/NavBar'
import { Header } from 'components/Header'
import { useSpaProvider } from 'hooks/spaRouterContext'
import { HeaderMobile } from 'components/HeaderMobile'
import { useScreenWidth } from 'utils/useScreenWidth'
import MobileCarouselComments from 'components/MobileCarouselComments'
import MobileCarouselProperties from 'components/MobileCarouselProperties'
import { useNavigate } from 'react-router-dom'
import { useHome } from 'pages/Home/hooks/useHome'
import { comments } from 'constants/data'

type CardProps = {
    imageOrIcon: string
    title: string
    description: string
}

type CardPropsOmitDescription = Omit<CardProps, 'description'>

type ItemListProps = {
    has: boolean,
    description: string
}

type BenefitsProps = {
    title: string
    description: string
    list: ItemListProps[]
}

const confirmeDark = require('assets/svg/flexhousing/ConfirmDark.svg').default
const confirmeWhite = require('assets/svg/flexhousing/ConfirmWhite.svg').default
const crossSymbol = require('assets/svg/flexhousing/CrossSymbol.svg').default
const smartPhone = require('assets/image/flexhousing/SmartPhone.png')


const CardAttributes = ({ imageOrIcon, description, title }: CardProps) => {
    return (
        <S.CardAttributesContainer>
            <img src={imageOrIcon} alt="" />
            <strong>{title}</strong>
            <p>{description}</p>
        </S.CardAttributesContainer>
    )
}

const cardAttributes = [
    {
        imageOrIcon: require('assets/svg/flexhousing/Newspaper.svg').default,
        title: 'Livre de burocracia',
        description: 'Conte com contratos simplificados de locação de moradias incríveis. Alugue em 3 passos e mude-se em até 24 horas após a reserva.'
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Clock.svg').default,
        title: 'Fique o tempo que quiser',
        description: 'Um morar flexível é sobre ter total controle de por quanto tempo vai ficar. Fique por dias, meses ou anos.'
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Buildings.svg').default,
        title: 'Unidades prontas para morar',
        description: 'Nossos apês são 100% equipados e decorados, com enxoval completo. É só chegar e morar.'
    },
]

const CardYoghaServices = ({ description, imageOrIcon, title }: CardProps) => {
    return (
        <S.CardYogharServiceContainer>
            <img src={imageOrIcon} alt="" />
            <S.CardYogharServiceColumn>
                <strong>{title}</strong>
                <p>{description}</p>
            </S.CardYogharServiceColumn>
        </S.CardYogharServiceContainer>
    )
}



const Amenities = ({ imageOrIcon, title }: CardPropsOmitDescription) => {
    return (
        <S.AmenitiesContainer>
            <img src={imageOrIcon} alt="" />
            <p>{title}</p>
        </S.AmenitiesContainer>
    )
}

const amenities: CardPropsOmitDescription[] = [
    {
        imageOrIcon: require('assets/svg/flexhousing/CleaningServices.svg').default,
        title: 'Serviços de limpeza',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Trousseau.svg').default,
        title: 'Trocas de enxoval',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Maintenance.svg').default,
        title: 'Manutenção',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Suport.svg').default,
        title: 'Suporte 24h',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Concierge.svg').default,
        title: 'Portarias 24 horas',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Locks.svg').default,
        title: 'Fechaduras eletrônicas',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Yoga.svg').default,
        title: 'Aulas de Yoga',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Personal.svg').default,
        title: 'Personal Trainner',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Pilates.svg').default,
        title: 'Aulas de Pilates',
    },
]

const GadgetServices = ({ imageOrIcon, title, description }: CardProps) => {
    return (
        <S.GadgetServicesContainer>
            <div>
                <img src={imageOrIcon} alt="" />
            </div>
            <strong>{title}</strong>
            <p>{description}</p>
        </S.GadgetServicesContainer>
    )
}

const gadgetServices: CardProps[] = [
    {
        imageOrIcon: require('assets/svg/flexhousing/MagnifyingGlass.svg').default,
        title: 'Busque o seu apê',
        description: 'Escolha a moradia mais adequada para você.'
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Calendar.svg').default,
        title: 'Faça a sua reserva',
        description: 'Pague em até 10x no cartão e conclua a sua reserva.'
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Warehouse.svg').default,
        title: 'Comece a morar',
        description: 'Nossos apês são equipados e decorados. Mude-se em até 24h'
    },
]

const InfoCard = ({ imageOrIcon, title }: CardPropsOmitDescription) => {
    return (
        <S.InfoCardContainer>
            <div>
                <img src={imageOrIcon} alt="" />
            </div>
            <p>{title}</p>
        </S.InfoCardContainer>
    )
}

const infoCard: CardPropsOmitDescription[] = [
    {
        imageOrIcon: require('assets/svg/flexhousing/Mallet.svg').default,
        title: 'Cafés',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Door.svg').default,
        title: 'Mini Markets',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Rocket.svg').default,
        title: 'Restaurantes',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/SocialTshirt.svg').default,
        title: 'Lavanderias Omo',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Bed.svg').default,
        title: 'Serviços de Spa',
    },
    {
        imageOrIcon: require('assets/svg/flexhousing/Kuritibike.svg').default,
        title: 'Kuritibike',
    },
]


const BenefitsTable = ({ description, list, title, variant }: BenefitsProps & { variant: boolean }) => {
    return (
        <S.BenefitsTableContainer variant={variant}>
            <div>
                <span>{title}</span>
                <p>{description}</p>
            </div>
            <ul>
                {list.map((item) => {
                    return (
                        <li key={item.description}>
                            <img src={item.has && variant ? confirmeWhite : item.has ? confirmeDark : crossSymbol} alt="" />
                            <p>{item.description}</p>
                        </li>
                    )
                })}
            </ul>
        </S.BenefitsTableContainer>
    )
}

const benefitsTable: BenefitsProps[] = [
    {
        title: 'Aluguel tradicional',
        description: 'Modelo mais utilizado de aluguéis residenciais.',
        list: [
            {
                description: 'Assinatura burocrática',
                has: false
            },
            {
                description: 'Contratos de longa duração',
                has: false
            },
            {
                description: 'Apês prontos para morar.',
                has: false
            },
            {
                description: 'Taxas de plataforma',
                has: false
            },
            {
                description: 'Serviços On Demand',
                has: false
            },
            {
                description: 'Comunicação limitada',
                has: false
            },
        ]
    },
    {
        title: 'Na Yogha',
        description: 'Moradia flexível para uma experiência que combina com você.',
        list: [
            {
                description: 'Assinatura simplificada',
                has: true
            },
            {
                description: 'Fique o tempo que quiser',
                has: true
            },
            {
                description: 'Apês prontos para morar',
                has: true
            },
            {
                description: 'Sem taxas de plataforma',
                has: true
            },
            {
                description: 'Serviços OnDemand',
                has: true
            },
            {
                description: 'Comunicação facilitada',
                has: true
            },
        ]
    },
    {
        title: 'Outras plataformas',
        description: 'Modelo mais utilizado para aluguéis de temporada.',
        list: [
            {
                description: 'Assinatura Simplificada',
                has: true
            },
            {
                description: 'Fique o tempo que quiser',
                has: true
            },
            {
                description: 'Apês prontos para morar',
                has: true
            },
            {
                description: 'Valores mais altos',
                has: false
            },
            {
                description: 'Serviços On Demand',
                has: false
            },
            {
                description: 'Comunicação limitada',
                has: false
            },
        ]
    },
]

export const FlexHousing = () => {

    document.title = "Moradia flexível | Yogha";

    const breakpoint = useScreenWidth()

    const cardYoghaServices: CardProps[] = [
        {
            imageOrIcon: breakpoint ? require('assets/image/flexhousing/YoghaService1Mobile.png') : require('assets/image/flexhousing/YoghaService1.png'),
            title: 'Serviços On Demand',
            description: 'Serviços prestados sob demanda, gerando facilidade e comodidade à sua estadia.'
        },
        {
            imageOrIcon: breakpoint ? require('assets/image/flexhousing/YoghaService2Mobile.png') : require('assets/image/flexhousing/YoghaService2.png'),
            title: 'App Suite',
            description: 'Conectamos você a descontos e vantagens em serviços e marcas que facilitam o seu dia a dia.'
        },
        {
            imageOrIcon: breakpoint ? require('assets/image/flexhousing/YoghaService3Mobile.png') : require('assets/image/flexhousing/YoghaService3.png'),
            title: 'Yogha Design',
            description: 'More em apês charmosos e completos, decorados de forma autêntica com o Yogha Design.'
        },
    ]

    const [selected, setSelected] = useState(0);
    const [shouldFocus, setShouldFocus] = useState(false);
    const [units, setUnits] = useState<any[]>([]);
    const [promotionalUnits, setPromotinalUnits] = useState<any[]>([]);
    const [buildings, setBuildings] = useState<any[]>([]);

    const { setExpanded } = useSpaProvider()

    const navigate = useNavigate()

    function filterPropertiesWithEmphasys(properties: Record<string, any>[]): Record<string, any>[] {
        return properties.filter(property => property.emphasys === true);
    }

    const fetchAccommodations = async () => {
        const { data } = await fetchAxios.post(`accommodations`, {
            "checkIn": "",
            "checkOut": "",
            "home": true
        });

        let mappedProperties = mapGetProperties(data.data);
        let sortedProperties = mappedProperties.sort((a, b) => a.uniqueValue - b.uniqueValue);
        let propertiesWithEmphasys = filterPropertiesWithEmphasys(mappedProperties);
        let sortedPropertiesWithEmphasys = propertiesWithEmphasys.sort((a, b) => b.uniqueValue - a.uniqueValue);

        return {
            promotionalUnits: sortedProperties.slice(0, 9),
            units: sortedPropertiesWithEmphasys.slice(0, 9),
        };
    }

    const { data, isError, isLoading, error, } = useQuery('accommodations', fetchAccommodations)

    const fetchBuilding = async () => {
        const { data } = await fetchAxios.get(`accommodations/buildings/list`,);
        let mappedProperties = mapBuilding(data.data);
        return {
            buildings: mappedProperties.slice(0, 9),
        };
    }

    const { data: dataBuilding, isError: isErrorBuilding, isLoading: loadingBuilding, error: errorBuilding } = useQuery('accommodations/buildings/list', fetchBuilding)


    useEffect(() => {
        if (data) {
            setPromotinalUnits(data.promotionalUnits)
            setUnits(data.units)
        }
        const fetchBuilding = async () => {
            const { data } = await fetchAxios.get(`accommodations/buildings/list`,);
            setBuildings(mapBuilding(data.data));
        }
        fetchBuilding()
    }, [data]);

    function handleTitles(): string {
        switch (selected) {
            case 0:
                return 'Apês em destaque'
            case 1:
                return 'Oportunidades para você'
            case 3:
                return 'Cidades mais procuradas'
            case 2:
                return 'Sugestões de unidades Yogha'
            default:
                return 'Em destaque'
        }
    }


    function handleCarousel(): any {
        switch (selected) {
            case 0: {
                return breakpoint ? <MobileCarouselProperties type='default' hasFrom card='building' key={`carousel-${selected}`} titleCarousel={carouselTitle} data={units} /> : <ImageCarousel type='default' hasFrom card='building' key={`carousel-${selected}`} titleCarousel={carouselTitle} data={units} />;
            }
            case 1: {
                return breakpoint ? <MobileCarouselProperties type='default' card='unit' key={`carousel-${selected}`} titleCarousel={carouselTitle} data={promotionalUnits} /> : <ImageCarousel type='default' card='unit' key={`carousel-${selected}`} titleCarousel={carouselTitle} data={promotionalUnits} />;
            }
            case 2: {
                return breakpoint ? <MobileCarouselProperties hasFrom type='unit' card='building' key={`carousel-${selected}`} titleCarousel={carouselTitle} data={buildings} /> : <ImageCarousel type='unit' hasFrom card='city' key={`carousel-${selected}`} titleCarousel={carouselTitle} data={buildings} />;
            }
            case 3: {
                return <ImageCarousel type='' card='unit' key={`carousel-${selected}`} titleCarousel={carouselTitle} data={buildings} />;
            }
        }
    }

    const carouselTitle = handleTitles()

    const carousel = handleCarousel()

    const happyPeople = breakpoint ? require('assets/image/flexhousing/GirlsHavingFunMobile.png') : require('assets/image/flexhousing/GirlsHavingFun.png')

    const smartPhone = breakpoint ? require('assets/image/flexhousing/SmartPhoneMobile.png') : require('assets/image/flexhousing/SmartPhone.png')
    const appSuiteBackground = breakpoint ? require('assets/image/flexhousing/AppSuiteBackgroundMobile.png') : require('assets/image/flexhousing/AppSuiteBackground.png')
    const yoghaDesign = breakpoint ? require('assets/image/owner/designphotomobile.png') : require('assets/image/owner/designphoto.png')

    const handleChoseAccommodation = () => {
        if (breakpoint) {
            navigate('/search-location')
        } else {
            setExpanded(false)
            setShouldFocus(true)
        }
    }

    return (
        <>
            <S.Container>
                {breakpoint ? <HeaderMobile /> : <Header />}
                <S.InitialSectionContainer>
                    <S.InitialSection>
                        <S.InitialColumn>
                            <h2>Descubra um novo jeito de viver! </h2>
                            <p>Nós flexibilizamos a moradia para dar a você a liberdade de viver a vida no seu próprio ritmo.</p>
                        </S.InitialColumn>
                    </S.InitialSection>
                    <S.HeadingTitle style={{
                        marginBlock: breakpoint && -32 || 0
                    }}>
                        Uma <S.Highligh>experiência de moradia</S.Highligh> simplificada
                    </S.HeadingTitle>
                    <S.InitialCardGroup>
                        {cardAttributes.map((card) => {
                            return (
                                <CardAttributes
                                    key={card.title}
                                    imageOrIcon={card.imageOrIcon}
                                    title={card.title}
                                    description={card.description}
                                />
                            )
                        })}
                    </S.InitialCardGroup>
                </S.InitialSectionContainer>

                <S.YoghaServicesContainer>
                    <S.YoghaServicesHeader>
                        <S.HeadingTitle>
                            Viva uma <S.Highligh>experiência memorável</S.Highligh>
                        </S.HeadingTitle>
                        <p>Descubra uma rede exclusiva de serviços inovadores e privilégios exclusivos, feitos sob medida para você.</p>
                    </S.YoghaServicesHeader>
                    <S.CardYogharServiceGroup>
                        {cardYoghaServices.map((card) => {
                            return (
                                <CardYoghaServices
                                    key={card.title}
                                    imageOrIcon={card.imageOrIcon}
                                    title={card.title}
                                    description={card.description}
                                />
                            )
                        })}
                    </S.CardYogharServiceGroup>
                </S.YoghaServicesContainer>

                <S.AmenitiesSectionContainer>
                    <S.HeadingTitle>
                        Nós <S.Highligh>cuidamos de tudo</S.Highligh> para que você tenha uma estadia sem preocupações.
                    </S.HeadingTitle>
                    <S.CardAmenitiesGroup>
                        {amenities.map((card) => {
                            return (
                                <Amenities
                                    key={card.title}
                                    imageOrIcon={card.imageOrIcon}
                                    title={card.title}
                                />
                            )
                        })
                        }
                    </S.CardAmenitiesGroup>
                </S.AmenitiesSectionContainer>

                <S.AppSuiteSectionContainer>
                    <S.AppSuiteBackground src={appSuiteBackground} alt="" />
                    <S.AppSuiteContent>
                        <img src={smartPhone} alt="" />
                        <S.AppSuiteColumnRight>
                            <S.AppSuiteColumnText>
                                <S.HeadingTitle>AppSuite</S.HeadingTitle>
                                <p>Com o AppSuite, você aproveita descontos e vantagens imperdíveis em marcas e serviços pensados para facilitar seu dia a dia. Conheça: </p>
                            </S.AppSuiteColumnText>
                            <S.AppSuiteCardGroup>
                                {infoCard.map((card) => {
                                    return (
                                        <InfoCard
                                            key={card.title}
                                            imageOrIcon={card.imageOrIcon}
                                            title={card.title}
                                        />
                                    )
                                })
                                }
                            </S.AppSuiteCardGroup>
                        </S.AppSuiteColumnRight>

                    </S.AppSuiteContent>

                </S.AppSuiteSectionContainer>
                <S.YoghaDesingSectionContainer>
                    <img src={yoghaDesign} alt="" />
                    <S.YoghaDesingSectionContainerColumn>
                        <S.YoghaDesingSectionText>
                            <h4>Yogha Design</h4>
                            <p>Quer morar em um apê que tem a sua cara? Então você vai amar conhecer os nossos apês decorados com o Yogha Design. Aqui, você encontra apês que combinam conforto, estilo e personalidade. Encante-se com os nossos apês e escolha já o seu favorito.</p>
                        </S.YoghaDesingSectionText>
                        <S.ButtonContainer>
                            <ButtonGradient onClick={handleChoseAccommodation} title='Escolher o meu apê' width='100%' />
                        </S.ButtonContainer>
                    </S.YoghaDesingSectionContainerColumn>
                </S.YoghaDesingSectionContainer>
                <S.GadgetServicesSectionContainer>
                    <S.HeadingTitle>
                        Veja como é <S.Highligh>simples</S.Highligh> encontrar o seu Yogha
                    </S.HeadingTitle>
                    <S.GadgetServicesGroup>
                        {gadgetServices.map((card) => {
                            return (
                                <GadgetServices
                                    key={card.title}
                                    imageOrIcon={card.imageOrIcon}
                                    title={card.title}
                                    description={card.description}
                                />
                            )
                        })}
                    </S.GadgetServicesGroup>
                </S.GadgetServicesSectionContainer>
                <S.CaroselSectionContainer>
                    <S.HeadingTitle>
                        Apês que combinam com você
                    </S.HeadingTitle>
                    <S.CaroselWithNavContainer>
                        <NavContainer >
                            <NavBar selected={selected} setSelected={setSelected} />
                        </NavContainer>
                        <S.CarouselContainer >
                            {carousel}
                        </S.CarouselContainer>
                    </S.CaroselWithNavContainer>
                </S.CaroselSectionContainer>
                <S.BenefitsSectionContainer>
                    <S.HeadingTitle>
                        Por que reservar com a <S.Highligh>Yogha</S.Highligh>
                    </S.HeadingTitle>
                    <S.BenefitsTableGroup>
                        {benefitsTable.map((card, index) => {
                            return (
                                <BenefitsTable
                                    key={card.title}
                                    title={card.title}
                                    description={card.description}
                                    list={card.list}
                                    variant={index === 1 ? true : false}
                                />
                            )
                        })
                        }
                    </S.BenefitsTableGroup>
                </S.BenefitsSectionContainer>
                <S.CommentsSectionContainer>
                    {breakpoint ? <MobileCarouselComments comments={comments} /> :
                        <>
                            <S.HeadingTitle>
                                O que dizem os nossos moradores
                            </S.HeadingTitle>
                            <SliderCommentsFlexHousing comments={comments} />
                        </>}
                </S.CommentsSectionContainer>
                <S.ReserveNowContainer>
                    <img src={happyPeople} alt="" />
                    <S.ReserveHeading>
                        <h3>Reserve agora a sua moradia e junte-se a milhares de pessoas que estão conhecendo o jeito Yogha de viver.</h3>
                        <S.ButtonContainer className='button'>
                            <ButtonGradient onClick={handleChoseAccommodation} title='Quero reservar' width='100%' />
                        </S.ButtonContainer>

                    </S.ReserveHeading>
                </S.ReserveNowContainer>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}>
                    <Footer />
                </div>
            </S.Container>
        </>
    )
}