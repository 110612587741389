import { useState, useRef,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectOption } from '.';
import { useSpaProvider } from 'hooks/spaRouterContext';
import { useAnyProvider } from 'hooks/Context';
import { useDebounce } from 'use-debounce';
import { fetchAxiosPayment } from 'services/http';
import { useUpdateQueryParams } from 'hooks/useUpdateQueryParam';

interface UseDropdownProps {
    getProperties?: (page: number, search?: string ) => Promise<void>;
    value?: any;
    onChange?: any;
    inputRef?: any;
    inputSearchValue?: any;
    isOpen?:boolean,
    shouldFocus?: any
    setShouldFocus?: any
    setIsOpen?: any
    setInputTypeSearchValue?: any
    setInputSearchValue?:any
}

export const useAutoCompletInput = ({getProperties,inputRef,isOpen,inputSearchValue, shouldFocus,setShouldFocus, setIsOpen, setInputSearchValue,setInputTypeSearchValue}:UseDropdownProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<any>([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [debouncedValue, setDebouncedValue] = useDebounce(inputSearchValue, 500);

  const { setExpanded } = useSpaProvider()

  const guestRef = useRef<any>(null)

  const updateQueryParam = useUpdateQueryParams()

  const navigate = useNavigate()

  const pathname = window.location.pathname

  const { setAutoCompletSearch, guest , date } = useAnyProvider()

  useEffect(() => {
    if (isOpen) {
      const getAutoComplete = async () => {
        try {
          if (debouncedValue) {
            setIsLoading(true);
            const { data } = await fetchAxiosPayment.get(`/search?query=${debouncedValue}`,)
            const filterData = (options: any, search: any) => {
              const seen = new Set();
              const searchLower = search.toLowerCase();

              // Primeiro, filtre os dados
              const filteredData = options.filter((item: any) => {
                const isMatch = item.termo.toLowerCase().includes(searchLower);
                const uniqueKey = `${item.termo}_${item.tipo}`;

                if (isMatch && !seen.has(uniqueKey)) {
                  seen.add(uniqueKey);
                  return true;
                }
                return false;
              });

              // Agora, ordene os dados
              const sortedData = filteredData.sort((a: any, b: any) => {
                const termA = a.termo.toLowerCase();
                const termB = b.termo.toLowerCase();

                // Verifica se os termos começam com a string de pesquisa
                const startsWithA = termA.startsWith(searchLower);
                const startsWithB = termB.startsWith(searchLower);

                // Ordena primeiro aqueles que começam com a string de pesquisa
                if (startsWithA && !startsWithB) {
                  return -1;
                } else if (!startsWithA && startsWithB) {
                  return 1;
                } else {
                  // Se ambos começam, ou não começam, com a string de pesquisa, compare os termos normalmente
                  return termA.localeCompare(termB);
                }
              });

              // Mapeie para o formato desejado
              return sortedData.map((item: any) => ({
                name: item.termo,
                type: item.tipo,
              }));
            };


            setResults(filterData(data, debouncedValue));
            setNoResultsFound(filterData(data, debouncedValue).length === 0);
            setIsLoading(false);
          } else {
            setResults([]);
            setNoResultsFound(false);
          }
        } catch (err) {
          setIsLoading(false);
          setNoResultsFound(false);
        }
      }
      getAutoComplete()
    }

    if (shouldFocus && inputRef.current && setShouldFocus) {
      setExpanded(false)
      inputRef.current.focus();
      setShouldFocus(false);
    }
  }, [isOpen, debouncedValue, shouldFocus]);

  const pressEnterSelectOption = (e?: React.KeyboardEvent<HTMLInputElement> | MouseEvent) => {
    let isEnterPress = false;
    let searchTerm = '';
    if (e && e.type === 'keydown') {
        const keyboardEvent = e as React.KeyboardEvent<HTMLInputElement>;
        isEnterPress = keyboardEvent.key === 'Enter';
    }
    const isButtonClick = !e || e instanceof MouseEvent;

    if(inputSearchValue.length > 0 && results.length > 0){
      searchTerm = results[0].name
      if (isEnterPress) {
            inputRef.current?.blur();
            setIsOpen(false);
            setExpanded(true);
            setInputTypeSearchValue(results[0].type);
            setInputSearchValue(searchTerm)
            setAutoCompletSearch(results[0].name + guest + date)
            if(!pathname.includes('/properties')){
              navigate(`/properties/?search=${searchTerm}&type=${results[0].type}`);
             }
          } else if(isButtonClick) {
            setExpanded(true);
            setInputTypeSearchValue(results[0].type);
            setInputSearchValue(results[0].name)
            setAutoCompletSearch(results[0].name + guest + date)
            if(!pathname.includes('/properties')){
              navigate(`/properties/?search=${searchTerm}&type=${results[0].type}`);
             }  else if (pathname.includes('/properties') && !pathname.includes(searchTerm)) {
              updateQueryParam({ search: searchTerm, type:results[0].type });
             }
            } else {
        }
    }
};


      return { results, isLoading, noResultsFound, debouncedValue , pressEnterSelectOption }
}