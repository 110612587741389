import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { ButtonCarousel } from 'components/ButtonCarousel';

interface CarouselProps {
    images: string[];
}

const MobileCarousel: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideRef = useRef<HTMLDivElement>(null);
    const autoSlideTimer = useRef<NodeJS.Timeout | null>(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const navigate = useNavigate()

    const images = useMemo(() => [
        {
            icon: require('assets/image/notices/MobileNews1.webp'),
            path: '/moradia-flexivel/'
        },
        {
            icon: require('assets/image/notices/MobileNews2.webp'),
            path: ''
        },
        {
            icon: require('assets/image/notices/MobileNews3.webp'),
            path: 'https://negocios.yogha.com.br/'
        }
    ], []);

    const nextSlide = useCallback(() => {
        setCurrentIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
        resetAutoSlideTimer(); // Reset the timer whenever slide changes
    }, [images.length]);

    const prevSlide = useCallback(() => {
        setCurrentIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
        resetAutoSlideTimer(); // Reset the timer whenever slide changes
    }, [images.length]);

    const resetAutoSlideTimer = useCallback(() => {
        if (autoSlideTimer.current) {
            clearTimeout(autoSlideTimer.current);
        }
        autoSlideTimer.current = setTimeout(nextSlide, 5000);
    }, [nextSlide]);

    useEffect(() => {
        if (imagesLoaded) {
            resetAutoSlideTimer();
        }
        return () => {
            if (autoSlideTimer.current) {
                clearTimeout(autoSlideTimer.current);
            }
        };
    }, [imagesLoaded, resetAutoSlideTimer]);

    useEffect(() => {
        // Check if images are loaded
        const imgElements = images.map(img => {
            const image = new Image();
            image.src = img.icon;
            return image;
        });

        let loadedImagesCount = 0;

        const handleLoad = () => {
            loadedImagesCount++;
            if (loadedImagesCount === images.length) {
                setImagesLoaded(true);
            }
        };

        const handleError = () => {
            loadedImagesCount++;
            if (loadedImagesCount === images.length) {
                setImagesLoaded(true);
            }
        };

        imgElements.forEach(img => {
            img.onload = handleLoad;
            img.onerror = handleError;
        });

        return () => {
            imgElements.forEach(img => {
                img.onload = null;
                img.onerror = null;
            });
        };
    }, [images]);

    return (<>
        <S.CarouselContainer ref={slideRef}>
            {images.map((image, index) => (
                <S.Slide
                    style={{
                        display: index === currentIndex ? 'block' : 'none'
                    }}
                    key={index}
                    src={image.icon}
                    alt={`Slide ${index}`}
                    active={index === currentIndex}
                    onClick={() => {
                        if (image.path.includes('https')) {
                            return window.location.assign(image.path)
                        } else {
                            navigate(image.path)
                        }
                    }
                    }
                />
            ))}
        </S.CarouselContainer>
        <S.ButtonContainer>
            <ButtonCarousel onClick={prevSlide} direction="left" />
            {images.map((_: any, i: number) => {
                return <S.Bullet key={i} active={currentIndex === i ? true : false} />;
            })}
            <ButtonCarousel onClick={nextSlide} direction="right" />
        </S.ButtonContainer>
    </>
    );
};

export default MobileCarousel;
