import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import Card from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { Input } from 'components/Input';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button } from 'components/Button';
import fetchAxios, { fetchAxiosPayment } from 'services/http';
import { useUserProvider } from 'hooks/userContext';
import axios, { AxiosError } from 'axios';
import { ProductCard } from 'components/ProductCard';
import useAlert from 'components/Notification';
import { NewInput } from 'components/NewInput';
import { IMaskInput } from 'react-imask';
import { Loading } from 'pages/Payment/components/Loading';

type CreditCardProps = {
    cvc: string;
    expiry: string;
    name: string;
    number: string;
}

const warningValidationIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM11.25 12.75V7.5C11.25 7.30109 11.329 7.11032 11.4697 6.96967C11.6103 6.82902 11.8011 6.75 12 6.75C12.1989 6.75 12.3897 6.82902 12.5303 6.96967C12.671 7.11032 12.75 7.30109 12.75 7.5V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75ZM13.125 16.125C13.125 16.3475 13.059 16.565 12.9354 16.75C12.8118 16.935 12.6361 17.0792 12.4305 17.1644C12.225 17.2495 11.9988 17.2718 11.7805 17.2284C11.5623 17.185 11.3618 17.0778 11.2045 16.9205C11.0472 16.7632 10.94 16.5627 10.8966 16.3445C10.8532 16.1262 10.8755 15.9 10.9606 15.6945C11.0458 15.4889 11.19 15.3132 11.375 15.1896C11.56 15.066 11.7775 15 12 15C12.2984 15 12.5845 15.1185 12.7955 15.3295C13.0065 15.5405 13.125 15.8266 13.125 16.125Z" fill="#ED374C" />
</svg>

type Focused = "name" | "number" | "expiry" | "cvc" | "";

export const RegisterCardMobile = () => {


    const navigate = useNavigate();

    const Logo = require('assets/image/logo.png')

    const arrowBack = require('assets/svg/LeftArrow.svg').default;


    const [stage, setStage] = useState<number>(1)
    const [validatePayment, setValidatePayment] = useState<boolean>(false)
    const [remoteIp, setIP] = useState<string>('')
    const [openModalSecurity, setOpenModalSecurity] = useState<boolean>(false)
    const [document, setDocument] = useState<number | string>('')
    const [documentUnformatted, setDocumentUnformatted] = useState<string>('')
    const [cardToken, setCardToken] = useState<string>('')
    const [amountCharged, setAmountCharged] = useState<string>('')
    const [idCardValidate, setIdCardValidate] = useState<string>('')
    const [amountChargedFormated, setAmountChargedFormated] = useState<string>("");
    const [cardError, setCardError] = useState<CreditCardProps>({
        cvc: '',
        expiry: '',
        name: '',
        number: '',
    })

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const [isFocus, setIsFocus] = useState<Focused | string>('')
    const [cardProps, setCardProps] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });
    const [address, setAddress] = useState({
        street: '',
        streetNumber: '',
        city: '',
        neighborhood: '',
        zipCode: '',
        complement: '',
    })

    const errorMessages: { [K in keyof CreditCardProps]: string } = {
        cvc: 'O CVC é obrigatório',
        expiry: 'A data de validade é obrigatória',
        name: 'O nome é obrigatório',
        number: 'O número do cartão é obrigatório',
    };

    const validateFields = (card: CreditCardProps) => {
        let errors = { ...cardError };

        for (const key in card) {
            if (card[key as keyof CreditCardProps].trim() === '') {
                errors = { ...errors, [key]: errorMessages[key as keyof CreditCardProps] };
            } else {
                errors = { ...errors, [key]: '' };
            }
        }

        setCardError(errors);
    };

    const { customer, token, userEmail, userPhone } = useUserProvider()

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get("https://api.ipify.org/?format=json");
            setIP(res.data.ip);
        };
        getData();
    }, [])

    const getLocationCep = async () => {
        try {
            const { data } = await axios.get(`https://viacep.com.br/ws/${address.zipCode}/json/`)
            setAddress({
                ...address,
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
            })
        } catch (error) {
        }

    }

    const flags = [
        require('assets/image/flag/amex.png'),
        require('assets/image/flag/banescard.png'),
        require('assets/image/flag/cabal.png'),
        require('assets/image/flag/discover.png'), require('assets/image/flag/elo.png'), require('assets/image/flag/hipercard.png'), require('assets/image/flag/jcb.png'), require('assets/image/flag/mastercard.png'), require('assets/image/flag/visa.png')]

    const handleRegisterCard = async () => {
        validateFields(cardProps)
        setValidatePayment(true)
        const lastNumber = cardProps.number.trim().replace(/\s/g, '').slice(-4)
        try {
            const { data } = await fetchAxios.put(`/payment/cards/${lastNumber}`, {
                "status": "RESTORE"
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

            if (data.code === 400) {
                try {
                    const formDataCard = new FormData();
                    formDataCard.append("customer", customer);
                    formDataCard.append("creditCard[holderName]", cardProps.name);
                    formDataCard.append("creditCard[number]", cardProps.number.trim().replace(/\s/g, ''));
                    formDataCard.append("creditCard[expiryMonth]", cardProps.expiry.slice(0, 2));
                    formDataCard.append("creditCard[expiryYear]", '20' + cardProps.expiry.slice(3, 5));
                    formDataCard.append("creditCard[ccv]", cardProps.cvc);
                    formDataCard.append("creditCardHolderInfo[name]", cardProps.name);
                    formDataCard.append("creditCardHolderInfo[email]", userEmail);
                    formDataCard.append("creditCardHolderInfo[cpfCnpj]", String(documentUnformatted));
                    formDataCard.append("creditCardHolderInfo[postalCode]", address.zipCode);
                    formDataCard.append("creditCardHolderInfo[addressNumber]", address.streetNumber);
                    formDataCard.append("creditCardHolderInfo[phone]", userPhone);
                    formDataCard.append("remoteIp", remoteIp);
                    formDataCard.append("token", token);

                    const resCard = await fetchAxiosPayment.post('/new-card', formDataCard, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    })
                    try {
                        const formDataValidate = new FormData();
                        formDataValidate.append("creditCardToken", resCard.data.token);
                        formDataValidate.append("customer", customer);
                        formDataValidate.append("token", token);
                        const res = await fetchAxiosPayment.post('/payment-card-confirm', formDataValidate, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        })
                        setIdCardValidate(res.data.chargeId)
                        setValidatePayment(false)
                        setStage(3)
                    } catch (error) {
                        setValidatePayment(false)
                        const axiosError = error as AxiosError;
                        if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === 'Transação não autorizada, verifique o limite disponível no cartão.') {
                            showAlert({ active: true, title: 'Transação não autorizada, verifique o limite disponível no cartão.', type: "ERROR" })
                        }
                    }

                } catch (error) {
                    setValidatePayment(false)
                    const axiosError = error as AxiosError;
                    if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === 'Cliente inválido ou não informado.') {
                        showAlert({ active: true, title: 'Documento inválido, informe um documento válido!', type: "ERROR" })
                    } else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "O cartão informado está expirado.") {
                        showAlert({ active: true, title: "O cartão informado está expirado.", type: "ERROR" })
                    } else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Informações de cartão de crédito são inválidas"
                    ) {
                        showAlert({ active: true, title: "Informações de cartão de crédito são inválidas.", type: "ERROR" })
                    } else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Informe o endereço do titular do cartão"
                    ) {
                        showAlert({ active: true, title: "Informações de cartão de crédito são inválidas.", type: "ERROR" })
                    } else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Campo obrigatório em \"creditCardHolderInfo\" faltando: addressNumber"
                    ) {
                        showAlert({ active: true, title: "Insira um número de endereço válido.", type: "ERROR" })
                    } else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message ===
                        "Campo obrigatório em \"creditCardHolderInfo\" faltando: postalCode"
                    ) {
                        showAlert({ active: true, title: "Insira um CEP válido.", type: "ERROR" })
                    }
                    else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message ===
                        "O CPF ou CNPJ informado é inválido."
                    ) {
                        showAlert({ active: true, title: "Insira um CPF ou CNPJ válido.", type: "ERROR" })
                    }
                    else if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message ===
                        "Campo obrigatório em \"creditCardHolderInfo\" faltando: cpfCnpj"
                    ) {
                        showAlert({ active: true, title: "Insira um CPF ou CNPJ válido.", type: "ERROR" })
                    }
                    else if (
                        axiosError.response?.data &&
                        typeof axiosError.response.data === 'object' &&
                        'message' in axiosError.response.data &&
                        typeof axiosError.response.data.message === 'string' &&
                        axiosError.response.data.message.includes("tokenizado")
                    ) {
                        const lastNumber = cardProps.number.trim().replace(/\s/g, '').slice(-4)
                        try {
                            const { data } = await fetchAxios.put(`/payment/cards/${lastNumber}`, {
                                "status": "RESTORE"
                            })
                            showAlert({ active: true, title: "Cartão recadastrado com sucesso!", type: "SUCCESS" });
                        } catch (error) {

                        }
                    }

                }
            } else {
                showAlert({ active: true, title: "Cartão recadastrado com sucesso!", type: "SUCCESS" });
                setValidatePayment(false)
            }
        } catch (error) {
            setValidatePayment(false)
            showAlert({ active: true, title: "Insira os dados do cartão!", type: "ERROR" });
        }


    }

    const handleConfirmCard = async () => {
        try {
            const formDataValidate = new FormData();
            formDataValidate.append("value", amountCharged.replace(',', '.'));
            formDataValidate.append("chargeId", idCardValidate);
            formDataValidate.append("token", token);
            const res = await fetchAxiosPayment.post('/card-confirm', formDataValidate, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
            showAlert({ active: true, title: 'Cartão cadastrado com sucesso!', type: "SUCCESS" })

            setTimeout(() => {
                navigate(-1)
            }
                , 2500);

        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === 'Cliente inválido ou não informado.') {
                showAlert({ active: true, title: 'Documento inválido, informe um documento válido!', type: "ERROR" })
            }
        }
    }

    const handleChangeAddress = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.currentTarget;

        if (name === 'streetNumber') {
            const numericValue = value.replace(/\D/g, '');
            setAddress((prev) => ({ ...prev, [name]: numericValue }));
        } else {
            setAddress((prev) => ({ ...prev, [name]: value }));
        }
    }

    const handleChangeCreditCard = (evt: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.currentTarget;
        if (name === 'cvc') {
            let formattedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
            formattedValue = formattedValue.slice(0, 3); // Limit input to max 3 characters
            setCardProps((prev) => ({ ...prev, [name]: formattedValue }));
        } else if (name === 'name') {
            const formattedValue = value.replace(/\d/g, ''); // Remove numeric characters
            setCardProps((prev) => ({ ...prev, [name]: formattedValue }));
        } else if (name === 'expiry') {
            let formattedValue = value.replace(/\D/g, ''); // Remove non-numeric characters

            if (parseInt(formattedValue.slice(0, 2)) > 12) { // Check if first 2 digits exceed 12
                formattedValue = '';
            } else if (formattedValue.length > 2) {
                formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2, 4);
            }
            setCardProps((prev) => ({ ...prev, [name]: formattedValue }));
        } else if (name === 'number') {
            const maskedValue = value.replace(/\D/g, '')
                .slice(0, 16)
                .split('')
                .map((digit, idx) => ((idx % 4 === 0 && idx !== 0) ? ` ${digit}` : digit))
                .join('');

            setCardProps({ ...cardProps, number: maskedValue });
        }

        else {
            setCardProps((prev) => ({ ...prev, [name]: value }));
        }
    };


    const handleInputFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
        setCardProps((prev) => ({ ...prev, focus: evt.target.name }));
    }

    const onClick = (evt: React.MouseEvent<HTMLInputElement>) => {
        const name = evt.currentTarget.name;
        setIsFocus(name);
    }



    function handleChangeCEP(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value.replace(/\D/g, '');
        const maskedValue = value.slice(0, 8).replace(/^(\d{5})(\d)/, '$1-$2');

        setAddress({ ...address, zipCode: maskedValue });
    }

    const handleNextStage = () => {
        if (stage === 1) {
            setStage(2)
        } else if (stage === 2) {
            handleRegisterCard()
        } else if (stage === 3) {
            handleConfirmCard()
        }

    }

    const checkIcon = require('assets/svg/payment/Check.svg').default
    const closeIcon = require('assets/svg/payment/X.svg').default

    function handleChangeDocument(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value.replace(/\D/g, '');
        let maskedValue;
        let unfomattedValue = value;

        if (value.length <= 11) {
            // Máscara de CPF (999.999.999-99)
            maskedValue = value
                .split('')
                .map((digit, idx) => {
                    if (idx === 3 || idx === 6) return `.${digit}`;
                    if (idx === 9) return `-${digit}`;
                    return digit;
                })
                .join('');
            unfomattedValue = value

        } else {
            // Máscara de CNPJ (99.999.999/9999-99)
            maskedValue = value
                .slice(0, 14)
                .split('')
                .map((digit, idx) => {
                    if (idx === 2 || idx === 5) return `.${digit}`;
                    if (idx === 8) return `/${digit}`;
                    if (idx === 12) return `-${digit}`;
                    return digit;
                })
                .join('');
            unfomattedValue = value.slice(0, 14)
        }
        setDocumentUnformatted(unfomattedValue);
        setDocument(maskedValue);
    }

    const warningIcon = require('assets/svg/WarningCircle.svg').default

    const backStage = () => {
        if (stage === 1) {
            return;
        } else {
            setStage((prev) => (prev - 1))
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        // Permitir apenas números e algumas teclas especiais como Backspace, Delete, ArrowLeft e ArrowRight
        if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(key)) {
            e.preventDefault();
        }
    };

    return (
        <S.Container>
            <S.Header>
                <S.HeaderContent>
                    <S.BackArrow src={arrowBack} alt="" onClick={() => navigate(-1)} />
                    <S.Logo onClick={() => navigate('/')} src={Logo} alt="" />
                </S.HeaderContent>
            </S.Header>
            <S.Title>Informações do cartão</S.Title>
            <S.ContentModal>
                {stage === 1 && (
                    <S.CollumnModal>
                        <S.InputContainer>
                            <Input onClick={onClick} htmlFor='number' onChange={handleChangeCreditCard}
                                placeholder='Digite apenas números' title='Número do cartão' type='text' value={cardProps.number} width={"100%"} errorText='' />
                            <Input onClick={onClick} htmlFor='name' onChange={handleChangeCreditCard} placeholder='Nome impresso no cartão' onFocus={handleInputFocus} title='Nome no cartão' type='text' width={"100%"} value={cardProps.name} />
                            <Input onClick={onClick} htmlFor='expiry' onFocus={handleInputFocus} onChange={handleChangeCreditCard} placeholder='MM/AA' title='Data de vencimento' type='' value={cardProps.expiry} width={"100%"} />
                            <Input onClick={onClick} onFocus={handleInputFocus} htmlFor='cvc' className='form-control' onChange={handleChangeCreditCard} placeholder='Código de segurança' title='CVV' type='text' value={cardProps.cvc} width={"100%"} />
                            <Input onClick={onClick} htmlFor='document' onChange={handleChangeDocument} placeholder='Número do CPF ou CNPJ do titular do cartão' title='CPF ou CNPJ do titular do cartão' type='text' value={document} width={"100%"} />
                        </S.InputContainer>
                        <S.ContainerCard>
                            <Card locale={{
                                valid: '',
                            }} placeholders={{
                                name: 'Nome no cartão',
                            }} preview cvc={cardProps.cvc.slice(0, 3)} expiry={cardProps.expiry} name={cardProps.name} number={cardProps.number} focused={isFocus as Focused} />
                            <S.FlagsContainer>
                                <p>Aceitamos</p>
                                <S.Flags>
                                    {flags.map((flag, index) => (
                                        <img key={flag + index} src={flag} alt="flag" />
                                    ))}
                                </S.Flags>
                            </S.FlagsContainer>
                        </S.ContainerCard>
                    </S.CollumnModal>
                )
                }
                {stage === 2 && (
                    <div>
                        <S.CollumnModalSecond>
                            <S.InputContainer>
                                <Input onClick={onClick} htmlFor='zipCode' onBlur={getLocationCep} onChange={handleChangeCEP}
                                    placeholder='Ex: 37660-000' title='CEP' type='text' value={address.zipCode} width={"332px"} />
                                <Input onClick={onClick} htmlFor='streetNumber' onChange={handleChangeAddress} placeholder='Ex: 548' onFocus={handleInputFocus} title='Número' type='text' width={"332px"} value={address.streetNumber} />
                                <Input onClick={onClick} htmlFor='neighborhood' onChange={handleChangeAddress} placeholder='Ex: Centro' title='Bairro' type='text' value={address.neighborhood} width={"332px"} />
                                <Input onClick={onClick} htmlFor='street' onChange={handleChangeAddress}
                                    placeholder='Ex: Rua Duque de Caxias' title='Endereço' type='text' value={address.street} width={"332px"} />
                                <Input onClick={onClick} htmlFor='complement' onChange={handleChangeAddress} placeholder='Ex: Apartamento, Casa' onFocus={handleInputFocus} title='Complemento (Opcional)' type='text' width={"332px"} value={address.complement} />
                                <Input onClick={onClick} readOnly htmlFor='city' onChange={handleChangeAddress} placeholder='Ex: Curitiba' onFocus={handleInputFocus} title='Cidade' type='text' width={"332px"} value={address.city} />
                            </S.InputContainer>
                        </S.CollumnModalSecond>
                        <S.WarningValidation>
                            <S.WarningHeader>
                                {warningValidationIcon}
                                <span>Validação de segurança</span>
                            </S.WarningHeader>
                            <p>Ao prosseguir, realizaremos uma cobrança de até R$10,00 para validar a titularidade do cartão. Este valor será estornado logo em seguida. Esta etapa expira em 15 minutos. Se não concluída em tempo, você poderá solicitar uma nova validação.
                            </p>
                        </S.WarningValidation>
                    </div>
                )}
                {stage === 3 && (
                    <S.CollumnModalValidateValue>
                        <S.HeaderValue>
                            <p>Uma cobrança de R$5,00 até R$10,00 foi realizada. Verifique a sua fatura ou extrato do cartão e Informe abaixo o valor exato da cobrança.</p>
                        </S.HeaderValue>
                        <S.ColumnValue>
                            <NewInput mask={''} name='value' placeholder='' type='text' onClick={onClick} htmlFor='value'
                                title='Valor cobrado:' width={"100%"}>
                                <IMaskInput onKeyDown={handleKeyDown} mask={'*,**'} type="text" placeholder='0,00' title='Valor cobrado:' onChange={(e) => setAmountCharged(e.currentTarget.value)} value={amountCharged} />
                            </NewInput>

                            <S.WarningRow onClick={() => setOpenModalSecurity(true)}>
                                <img src={warningIcon} alt="" /><p>Porque preciso fazer isso?</p>
                            </S.WarningRow>
                        </S.ColumnValue>
                    </S.CollumnModalValidateValue>
                )}
            </S.ContentModal>

            <S.ModalFooter stage={stage}>
                <Button widthSize={'100%'} onClickButton={handleNextStage} size='medium' title='Próximo' type='primary' />
                {stage !== 1 && stage !== 3 && <Button onClickButton={backStage} widthSize={'100%'} size='medium' title='Anterior' type='secondary' />}
            </S.ModalFooter>
            <ProductCard width='332' bottomLine isOpen={openModalSecurity} setIsOpen={setOpenModalSecurity} transparent title='Segurança'>
                <S.SecurityContent>
                    <S.SecurityText>
                        <p>Para proteger sua conta, realizamos essa cobrança como medida antifraude, para confirmar a titularidade do cartão. Não se preocupe, esse valor será estornado.</p>
                    </S.SecurityText>
                    <Button widthSize={'333px'} onClickButton={() => setOpenModalSecurity(false)} size='large' title='Tudo bem, eu entendi' type='primary' />
                </S.SecurityContent>
            </ProductCard>
            {validatePayment ? <Loading text='Aguarde enquanto validamos as suas informações' /> : null}
            <Alert />
        </S.Container>
    )

}