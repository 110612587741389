import styled from "styled-components";
import { NEUTRAL_COLORS, STATUS_COLORS } from "../../constants/styleConstants";

export const Container = styled.button`
box-sizing: border-box;

display: flex;
flex-direction: row;

align-items: center;
justify-content: center;

padding: 8px;

gap: 10px;

width: 32px;
height: 32px;

border: 2px solid ${NEUTRAL_COLORS.GRAY};
border-radius: 8px;

background-color: ${NEUTRAL_COLORS.WHITE};

flex: none;
order: 0;
flex-grow: 0;

svg {
    path {
      stroke: ${({ disabled }) => disabled ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK  };
    }
  }
`;