import styled, { css , keyframes} from 'styled-components';
import { FONT, FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { NEUTRAL_COLORS, PRIMARY_COLORS } from '../../constants/styleConstants';
import { LinkCard } from '../Card/types';
import {  motion } from 'framer-motion';

export const TextTitle = styled.h1`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  ${FONT.BODY.BODY_MEDIUM[600]}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BuildingCounts = styled.div`
  display: flex;

  li{
    list-style: none;
    ${FONT.CAPTION.CAPTION_SEMI_REGULAR};
  }

  li::before{
  content: '•'; /* Adiciona seu próprio marcador */
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  display: inline-block; /* Coloca o marcador inline com o texto */
  width: 12px; /* Espaço antes do texto */
  margin-left: 4px;
  }

  gap: 8px;
`;

export const TextPrice = styled.p`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-size: ${FONT_SIZE.SMALL} ;
  font-weight: ${FONT_WEIGTH.HUGE};
`;

export const Infos = styled.div`
display: flex;
flex-direction: column;
row-gap:8px;

@media (max-width: 768px) {
  width: calc(100vw - 54px);
}
`;

export const CollumnInfo = styled.div`
display: flex;
flex-direction: column;
row-gap:16px;
`;

export const TextOthers = styled.strong`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  gap: 4px;
  align-items: center;

  b{
    ${FONT.BODY.BODY_SMALL[400]}
  }
`;

export const TextComplement = styled.h2`
  display: flex;
  font-weight: ${FONT_WEIGTH.MEDIUM};
  font-size: ${FONT_SIZE.LITTLE};
  ${FONT.CAPTION.CAPTION_SEMI_REGULAR};
  `;

export const AddressText = styled.p`
color: ${NEUTRAL_COLORS.DARK_GRAY};
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_SIZE.SMALL};
`;


export const TextFrom = styled.p`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-weight: ${FONT_WEIGTH.MEDIUM};
  font-size: ${FONT_SIZE.LITTLE};
`;

export const PostContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  word-wrap: break-word !important;
  white-space: pre-wrap ;
  word-break: break-word !important;
    p{
      &:hover{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
  `

export const TextPost = styled.p`
  font-weight: ${FONT_WEIGTH.HUGE};
  font-size: ${FONT_SIZE.SMALL};
  color: ${NEUTRAL_COLORS.DARK};
`;

type SizeCard = Pick<LinkCard, 'size'>;

export const LinkContainer = styled.div<SizeCard>`
  display:flex;
  flex-direction:column;
  width:310px;
  height:${(props ) => props.size === 'unit' ? '338px' : props.size === 'post' ? '274px' : ''};;
  justify-content: center;
  align-items: center;
  border:1px solid ${NEUTRAL_COLORS.GRAY};
  border-radius: 10px;
  row-gap:32px;
`;


export const LinkText = styled.div`
width: 220px;
line-height: 1.4;
font-weight: ${FONT_WEIGTH.HUGE};
font-size: ${FONT_SIZE.SMALL};
color: ${NEUTRAL_COLORS.HIGH_DARK};
text-align: center;
word-wrap: break-word;
white-space: pre-wrap;
  `;

interface ScaleContainerProps {
  width?: string;
}

const transition = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
 })

export const ScaleContainer = styled.div<ScaleContainerProps>`
  display: flex;
  border-radius: 10px;
  position: relative;
  width: 100%;
  word-wrap: break-word;
  cursor: pointer;

  &:hover {
    h1{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
  @media (max-width: 1439px) {
  max-width: 100%;
}
@media (min-width: 1440px) {
  max-width: 100%;
}
@media (min-width: 1920px) {
  max-width: 440px;
}
@media (min-width: 2560px) {
  max-width: 392px;
}
${({ width }) => width && css`
${ButtonContainer}{
  margin-top: calc(0% + 80px) !important;
@media (min-width: 1280px) {
      margin-top: calc(0% + 80px) !important;
    }
}
${BulletContainer}{
  margin-top:175px;
@media (min-width: 1280px) {
  margin-top:175px;
}
}
`}
  `;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  word-wrap: break-word !important;
  white-space: pre-wrap ;
  word-break: break-word !important;
    p{
      &:hover{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
`;

export const CarouselContent = styled.div`
    display:flex;
    width: 100%;
    height: 488px;
    transform: translateX(${(props: { translate: any; }) => props.translate}%);
    transition: all 0.5s ease-in-out;
`;


export const CaroselImage = styled.div`
    width: 100%;`;

export const ImageContainer = styled.div``;

export const ScaleImage = styled(motion.img)`
  max-width: 100%;
  background-color: ${NEUTRAL_COLORS.GRAY};

  object-fit: cover;

  border-radius: 8px;

  margin-bottom: 16px;

@media (max-width: 550px) {
    min-height: 280px;
    max-height: 320px;
    height: 100%;
}

@media (min-width: 550px) AND (max-width: 768px) {
  min-height: 370px;
  max-height: 390px;
  height: 100%;
}

@media (min-width: 768px) {
  min-height: 280px;
  height: 100%;
}

@media (min-width: 1440px) {

  height: 284px;
}
@media (min-width: 1920px) {

  height: 284px;
}
@media (min-width: 2560px) {

  height: 284px;
}
`;

export const Favorites = styled.div`
position: absolute;
margin-top: 8px;
margin-left: calc(100% - 48px);
`;

export const Gadget = styled.div<{header?:boolean}>`
display: flex;
position: absolute;
align-items: center;
justify-content: center;

width: 106px;
height: 29px;
margin-top: 8px;
margin-left: ${props => props.header ? '8px' : "calc(0% + 8px)"};

background: ${NEUTRAL_COLORS.WHITE};

border-radius: 4px;

white-space: nowrap;

p{
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-size: ${FONT_SIZE.LITTLE};
  font-weight: ${FONT_WEIGTH.HUGE};
  &:hover{
    color: ${NEUTRAL_COLORS.HIGH_DARK};
  }
}

`;

export const ButtonContainer = styled.div<{hover:boolean}>`
    display: ${props => props.hover ? 'flex' : 'none'};
    position: absolute;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: calc(100% - 90px);
    margin-top: calc(0% + 90px);
    @media (min-width: 850px) AND (max-width: 1280px) {
      margin-top: calc(0% + 120px);
    }
    @media (min-width: 1280px) {
      margin-top: calc(0% + 130px);
    }

    @media (max-width: 850px) {
      bottom: calc(0% + 240px);
    }
    `;

export const BulletContainer = styled.div`
display: flex;
position: absolute;
gap: 8px;
margin-top: 200px;
margin-left: calc(100% / 2 - 24px);
@media (min-width: 1280px) {
  margin-top: 255px;
}

@media (max-width: 1280px) {
  bottom: 160px;
}


`;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 69px;
  background: ${NEUTRAL_COLORS.WHITE};
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  margin-right: calc(100% / 2);
  `;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
  `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.5s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.LIGHT_GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const RowComplementDefault = styled.div`
  display: flex;
  flex-direction: row;

  gap:8px;
`;

 type TotalWithDateDefinedProps = {
  colorText:'gray' | 'black'
  bullet?:boolean
};

export const TotalWithDateDefined = styled.p<TotalWithDateDefinedProps>`
  color: ${({colorText}) => colorText === 'gray' ? NEUTRAL_COLORS.DARK_GRAY : '' } ;
  ${FONT.BODY.BODY_SMALL[400]};
  ${props => props.bullet && css`
    &::before{
      content: "•";
      color: ${NEUTRAL_COLORS.DARK_GRAY};/* Cor do marcador */
      padding-right: 4px;
      font-size: 14px;
    }
  `}


`;