import * as S from './styles';

interface ButtonRemoveAndAddProps {
    button: 'add' | 'remove';
    disabled?: boolean;
    onClick?: () => void;
}

export const ButtonRemoveAndAdd = ({ button, disabled,onClick }: ButtonRemoveAndAddProps) => {
    return (
        <S.Container onClick={onClick} disabled={disabled}>
            {button === 'remove' ?
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 8H13.5" stroke="#161616" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                 :
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 8H13.5" stroke="#161616" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8 2.5V13.5" stroke="#161616" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            }
        </S.Container>
    );
}