import { zodResolver } from "@hookform/resolvers/zod"
import axios from "axios"
import { useSessionStorage } from "context/sessionStorage"
import { useAddressSchema } from "pages/Checkin/schemas/Address"
import { useEffect, useState } from "react"
import { FieldErrors, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { AddressFormDataInternational, AddressFormDataNatinal } from "./types"
import fetchAxios from "services/http"

export const useAddress = () => {

    const [contryYouLive, setCountryYouLive] = useState<string>('')
    const [states, setStates] = useState<{ value: string, label: string }[]>([])
    const [documentValidation, setDocumentValidation] = useState<string>('')
    const { saveToSessionStorage, getFromSessionStorage } = useSessionStorage()
    const { schema, schemaInternational, schemaNational } = useAddressSchema(contryYouLive);
    const navigate = useNavigate();

    type NationalAddressFormData = z.infer<typeof schemaNational>;
    type InternationalAddressFormData = z.infer<typeof schemaInternational>;
    type FormData = NationalAddressFormData | InternationalAddressFormData;

    useEffect(() => {
        const data: AddressFormDataNatinal | AddressFormDataInternational = getFromSessionStorage('address')
        if (!data) return
        if ('cep' in data) {
            setValue('address', data.address ?? '')
            setValue('cep', data.cep ?? '')
            setValue('city', data.city ?? '')
            setValue('complement', data.complement ?? '')
            setValue('district', data.district ?? '')
            setValue('number', data.number ?? '')
            setValue('state', data.state ?? { value: '', label: '' })
        } else {
            setValue('fullAddress', data.fullAddress ?? '')
            setValue('cityInternational', data.cityInternational ?? '')
            setValue('province', data.province ?? '')
        }
    }, [])

    const { handleSubmit, formState: { errors }, setValue, control, trigger, watch } = useForm<FormData>({
        resolver: zodResolver(schema),
    });

    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));


    function validateError(field: string, errors: FieldErrors<FormData>): string | undefined {
        // Attempting a direct access and handling it as any to bypass the compile-time check
        // NOTE: This reduces type safety and should be used with caution
        const error = (errors as any)[field]?.message;
        return error;
    }

    const OnSubmit = async (data: FormData) => {
        if (!!errors) {

            saveToSessionStorage('address', { ...data, validateAddress: true });
            if (documentValidation === 'PASSPORT') {
                navigate(`${pathnameActual}/verify-passport`, {replace: true})
            } else {
                navigate(`${pathnameActual}/verify-document`,{replace: true})
            }
        }
    }

    const handleBack = () => {
        navigate(`${pathnameActual}/personal-info`)
    }

    useEffect(() => {
        const data = getFromSessionStorage('address')
        const getLocationCep = (async () => {
            try {
                const { data } = await axios.get(`https://viacep.com.br/ws/${watch('cep')}/json/`)
                setValue('address', data.logradouro)
                setValue('district', data.bairro)
                setValue('city', data.localidade)
                const state = states.find((state) => state.label === data.uf)
                setValue('state', { label: data.uf, value: data.uf })
            } catch (error) {
            }
        })
        if (watch('cep') && watch('cep').length === 8 && data?.cep !== watch('cep')) {
            getLocationCep()
        }
    }, [watch('cep')])


    useEffect(() => {
        const dataPersonal = getFromSessionStorage('personal-info')
        if (dataPersonal) {
            setCountryYouLive(dataPersonal.countryYouLive.label)
            setDocumentValidation(dataPersonal.documentType)
        }
        const getStates = async () => {
            try {
                const data = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
                const states = data.data.map((state: any) => {
                    return { value: state.sigla, label: state.sigla }
                })
                setStates(states)
            } catch (error) {

            }
        }

        getStates()
    }, [])

    return {
        OnSubmit,
        handleBack,
        validateError,
        control,
        errors,
        handleSubmit,
        trigger,
        contryYouLive,
        states
    }
}