import { usePaymentProvider } from "context/paymentContext"
import { useSessionStorage } from "context/sessionStorage"
import dayjs from "dayjs"
import { useAnyProvider } from "hooks/Context"
import { useDateDifference } from "hooks/useDateDifference"
import { useUserProvider } from "hooks/userContext"
import { useFetchingPayment } from "pages/Payment"
import usePaymentStore from "pages/Payment/components/PaymentCard/useStorage"
import { usePaymentTypeProvider } from "pages/Payment/hooks/usePaymentType"
import { Service } from "pages/Product/components/CustomStayCard"
import { ReserverDataProps } from "pages/Product/types"
import { ReservesProps } from "pages/SpaceUser/pages/Reservations"
import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import fetchAxios from "services/http"

export const usePixCode = () => {
    const navigate = useNavigate()

    const { isFetching, setIsFetching } = useFetchingPayment()

    const { setPaymentData, pixDiscountedNightValue } = usePaymentStore()

    const { saveToSessionStorage, getFromSessionStorage, removeFromSessionStorage } = useSessionStorage()

    const { setTypePayment } = usePaymentTypeProvider()
    const [qrCode, setQrCode] = useState<string>('')
    const [inlineCode, setInlineCode] = useState<string>('')
    const [copy, setCopy] = useState<string>('Copiar')
    const [tokenAsaas, setTokenAsaas] = useState<string>('')
    const [validTimePix, setValidTimePix] = useState<string>('')
    const [validDayPix, setValidDayPix] = useState<string>('')
    const [localizator, setLocalizator] = useState<string>('')
    const { token, userCustomerId } = useUserProvider()
    const { setReserveDetails } = usePaymentProvider()
    const [reserverInfo, setReserverInfo] = useState<any>()
    const { guest } = useAnyProvider()

    const [date, setDate] = useState<string[] | Date[] | null>([]);

    const { dayDifference: difference, weeklyDifference: weekValue } = useDateDifference(date)

    const validTimePixSumTwoHours = (createdPix: string) => {
        const createdDate = new Date(createdPix);
        createdDate.setHours(createdDate.getHours()); // Adjust for time difference
        const expiryDate = new Date(createdDate.getTime() + 2 * 60 * 60 * 1000); // add 2 hours to createdDate
        localStorage.setItem('expiryPix', expiryDate.toISOString());

        const now = new Date();
        let remainingTime = Math.floor((expiryDate.getTime() - now.getTime()) / 1000); // get remaining time in seconds

        if (remainingTime < 0) {
            // If remainingTime is negative, then the expiry time has passed
            remainingTime = 0;
        }

        let hours = Math.floor(remainingTime / 3600);
        let minutes = Math.floor((remainingTime % 3600) / 60);

        // Preenche com zeros à esquerda, se necessário
        hours = Number(hours.toString().padStart(2, '0'));
        minutes = Number(minutes.toString().padStart(2, '0'));

        const validHour = `${hours}h:${minutes}m`
        setValidDayPix(expiryDate.toISOString())
        setValidTimePix(validHour)
    }

    function formatDate(dataStr: string) {
        try {
            const dataObj = new Date(dataStr);

            const ano = dataObj.getUTCFullYear();
            const mes = String(dataObj.getUTCMonth() + 1).padStart(2, '0');
            const dia = String(dataObj.getUTCDate()).padStart(2, '0');

            // Formatar a data no padrão "YYYY-MM-DD"
            const dataFormatada = `${ano}-${mes}-${dia}`;
            return dataFormatada;
        } catch (error) {
            console.error("Erro: Formato de data inválido.");
            return null;
        }
    }


    const warningIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="WarningCircle">
            <path id="Vector" d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM11.25 12.75V7.5C11.25 7.30109 11.329 7.11032 11.4697 6.96967C11.6103 6.82902 11.8011 6.75 12 6.75C12.1989 6.75 12.3897 6.82902 12.5303 6.96967C12.671 7.11032 12.75 7.30109 12.75 7.5V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75ZM13.125 16.125C13.125 16.3475 13.059 16.565 12.9354 16.75C12.8118 16.935 12.6361 17.0792 12.4305 17.1644C12.225 17.2495 11.9988 17.2718 11.7805 17.2284C11.5623 17.185 11.3618 17.0778 11.2045 16.9205C11.0472 16.7632 10.94 16.5627 10.8966 16.3445C10.8532 16.1262 10.8755 15.9 10.9606 15.6945C11.0458 15.4889 11.19 15.3132 11.375 15.1896C11.56 15.066 11.7775 15 12 15C12.2984 15 12.5845 15.1185 12.7955 15.3295C13.0065 15.5405 13.125 15.8266 13.125 16.125Z" fill="black" />
        </g>
    </svg>

    const pathname = useLocation().pathname.split('/')[3]

    const getReserversUser = useCallback(async () => {
        try {
            setIsFetching(true);

            const { data } = await fetchAxios.get('/reservers/listReserver', {
                headers: { Authorization: `Bearer ${token}` }
            });

            const filterByPayment = data.filter((item: any) => item.id_asaas_charge === pathname);

            if (filterByPayment.length === 0) {
                console.warn('No reservations found for the provided pathname');
                setIsFetching(false);
                return;
            }

            const newReservationObject: ReservesProps[] = data.map((item: any) => ({
                id: item.idBooking,
                address: {
                    street: item.address,
                    number: item.street_number,
                    area: item.areaBuilding,
                    town: item.town,
                    state: 'PR'
                },
                accommodation: item.accommodation,
                initialDate: formatDate(item.arrival_date),
                finalDate: item.departure_date,
                type: item.booking_type,
                paymentType: item.type,
                image: 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/1280x1080/filters:quality(65)/' + item.featured_image,
                guests: item.adults_number,
                totalValue: item.value_total,
                title: item.name_ad,
                streetNumbe: item.street_number,
                ref_stays: item.accRefStays,
                idBuilding: item.building_yogha,
                dateEdit: item.dateReserver,
                checkinCode: item.token,
                statusCheckin: item.statusCheckin,
                paymentId: item.id_asaas_charge
            }));

            const filterReservation = newReservationObject.filter((item: any) => item.paymentId === pathname);

            if (filterReservation.length === 0) {
                console.warn('No reservations found after mapping');
                setIsFetching(false);
                return;
            }

            const [initialDate, finalDate] = [dayjs(filterReservation[0].initialDate).toDate(), dayjs(filterReservation[0].finalDate).toDate()];

            if (!date || date.length === 0) {
                setDate([initialDate, finalDate]);
            }

            const reserveDetails = {
                mainImage: filterReservation[0].image,
                accommodationName: filterReservation[0].title,
                address: {
                    city: filterReservation[0].address.town,
                    area: filterReservation[0].address.area,
                    stateCode: filterReservation[0].address.state,
                    street: filterReservation[0].address.street,
                },
                id: String(filterReservation[0].id),
                checkIn: filterReservation[0].initialDate,
                checkOut: filterReservation[0].finalDate,
            };

            const response = await fetchAxios.post(`/accommodations/accommodationValue/${filterReservation[0].ref_stays}`, {
                checkIn: filterReservation[0].initialDate,
                checkOut: filterReservation[0].finalDate,
            });

            const filterServices = (services: Service[], filterString: string) => {
                return services.filter(service => service.name.includes(filterString));
            };

            const filteredServices = filterServices(response.data.services, "Limpeza Final");
            const valueCleaning = filteredServices.length > 0 ? filteredServices[0].value : 0;
            const newPaymentData = {
                cleaningValue: Number(valueCleaning) * weekValue,
                midStayValue: Number(response.data.midStayTaxa),
                nightValue: difference > 1 ? Number(response.data.sumDailyValues) : Number(response.data.uniqueValue),
                condominiumValue: Number(response.data.condominium),
                iptu: Number(response.data.iptu),
                pacoteYogha: Number(response.data.yogha_pack),
                reserveDetails: reserveDetails,
                weekValue: weekValue,
                difference: difference,
                total: Number(filterReservation[0].totalValue),
                valueWeek: Number(valueCleaning) * weekValue,
                discount: false,
            };


            if (difference > 30) {
                const rateCondominium = Math.floor(response.data.condominium);
                const rateIptu = Math.floor(response.data.iptu);
                const rateYoghaPackageTax = Math.floor(response.data.yogha_pack);

                setPaymentData({
                    ...newPaymentData,
                    cleaningValue: Number(valueCleaning) * weekValue,
                    condominiumValue: Number(rateCondominium.toFixed(0)),
                    iptu: Number(rateIptu.toFixed(0)),
                    pacoteYogha: Number(rateYoghaPackageTax.toFixed(0)),
                });
            } else {
                setPaymentData(newPaymentData);
            }


        } catch (error) {
            console.error('Error fetching reservers:', error);
        } finally {
            setIsFetching(false);
        }
    }, [token, pathname, date, difference, weekValue, setDate]);


    useEffect(() => {
        if (date && date.length === 2 && difference !== 0) {
            getReserversUser();
        }
    }, [difference]);

    useEffect(() => {
        if (!reserverInfo) {
            const reserver: ReserverDataProps = getFromSessionStorage('reserver')
            if (reserver) {
                setReserverInfo(reserver)
            }
        }
    }
        , [])

    const getQRCode = async () => {
        setIsFetching(true)
        try {
            const res = await fetchAxios.get(`payment/checkPayment/${pathname}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setLocalizator(res.data.localizator)
            setTokenAsaas(res.data.payment.id_asaas_charge)
            setQrCode(res.data.qrCode)
            setInlineCode(res.data.payload)
            validTimePixSumTwoHours(res.data.payment.created_at)
            setTypePayment('PIX')
            saveToSessionStorage('hasCodePix', 'true')
            localStorage.setItem('createdPix', res.data.payment.created_at);
        } catch (error) {
        } finally {
            setIsFetching(false)
        }

    }

    useEffect(() => {
        return () => {
            removeFromSessionStorage('hasCodePix')
        }
    }, [])


    useEffect(() => {
        let intervalId: any = null;

        const fetchQRCodeAndVerifyPayment = async () => {
            if (qrCode === '') {
                await getQRCode();
                await getReserversUser()
            }

            intervalId = setInterval(verifyPixPayment, 10 * 1000);
        };

        fetchQRCodeAndVerifyPayment();

        // Call validTimePixSumTwoHours initially to set the timer
        const createdPix = localStorage.getItem('createdPix');
        validTimePixSumTwoHours(createdPix!);

        // Update the timer every minute
        const timerId = setInterval(() => {
            validTimePixSumTwoHours(createdPix!);
        }, 60 * 1000);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            if (timerId) {
                clearInterval(timerId);
            }
            setReserveDetails({
                mainImage: '',
                accommodationName: '',
                address: {
                    city: '',
                    area: '',
                    stateCode: '',
                    street: '',
                },
                id: '',
                checkIn: '',
                checkOut: '',
            })
        };
    }, [qrCode]);

    const isShortStay = difference < 27 ? 'Shortstay' : 'Midstay';

    const verifyPixPayment = async () => {

        const objDatalayer = {
            'event': 'purchase',
            'building': reserverInfo && reserverInfo.nameBuilding, //Building name//
            'city': reserverInfo && reserverInfo.city, //Cidade do edifício//
            'conversion_value': reserverInfo && reserverInfo.total_price, //Valor total da reserva//
            'transaction_ID': localizator, //Identificação da reserva//
            'currency': 'BRL', //Inicialmente, traremos apenas a currency 'BRL'//
            'product_ID': reserverInfo && reserverInfo.accommodation_id, //Identificação da unidade//
            'product_name': reserverInfo && reserverInfo.nameAcc, //Nome do imóvel//
            'client_ID': userCustomerId, //Identificação do cliente na tabela customer//
            'booking_type': isShortStay,
            'guest_number': guest, //Número de hóspedes//
            'payment_type': 'PIX'
        }

        try {
            const { data } = await fetchAxios.post('payment/check', {
                tokenAssas: tokenAsaas,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (data.status === 'RESERVED') {
                if ((window as any).dataLayer) {
                    (window as any).dataLayer.push(objDatalayer)
                }
                navigate(`/payment/${tokenAsaas}/confirmado`)
            } else if (data.status === 'CANCELLED') {
                navigate(`/payment/${tokenAsaas}/erro-de-pagamento`)
            }
        } catch (erro) {
            console.error('Falha ao buscar o status do pagamento:', erro);
        }
    };

    const handleCopyInlineCode = async () => {
        try {
            await navigator.clipboard.writeText(inlineCode);
            setCopy("Copiado");
            setTimeout(() => {
                setCopy("Copiar");
            }, 2500);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const pixIcon = require('assets/svg/payment/PixIcon.svg').default

    return {
        qrCode,
        inlineCode,
        copy,
        validTimePix,
        warningIcon,
        handleCopyInlineCode,
        pixIcon,
        isFetching,
    }
}