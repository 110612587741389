import { Banner } from 'components/Banner';
import { ImageCarousel } from 'components/ImageCarousel';
import { Section } from 'components/Section';
import { SliderComments } from 'components/SliderComments';
import { Container } from './styles';
import { sectionsIncorporators, sectionsProprietary } from './data'
import MobileCarouselProperties from 'components/MobileCarouselProperties';
import MobileCarouselComments from 'components/MobileCarouselComments';
import * as S from './styles'
import { WhatsappButton } from 'components/WhatsappButton';
import { useHome } from 'pages/Home/hooks/useHome';
import { comments } from 'constants/data';
import { HomeSkeleton } from '../Skeleton';


type BodyProps = {
  accommodations: any;
  buildings: any;
  blog: any;
  isFetching: boolean
}

export const Body = ({ accommodations, buildings, blog, isFetching }: BodyProps) => {

  const { notices, image, accomodationsWithLink, buildingsWithLink, imageMobile, postsWithLink, navigate, breakpoint, } = useHome({ accommodations, buildings, blog, isFetching })

  return (
    <Container>
      {isFetching ? (
        <HomeSkeleton />
      ) : (
        <>
          <div style={{ alignSelf: 'center' }}>
            <Banner notices={notices} imageNotice={breakpoint ? imageMobile : image} />
          </div>
          <S.CarouselContainer>
            {breakpoint && <S.TitleCarrousel>
              Oportunidades em destaque
            </S.TitleCarrousel>}
            {breakpoint ? <MobileCarouselProperties hasFrom link card='unit' data={accomodationsWithLink} titleCarousel='Oportunidades em destaque' type='default' /> : <ImageCarousel type='default' key={'unit'} card={'unit'} link titleCarousel='Oportunidades em destaque' hasFrom data={accomodationsWithLink} />}
          </S.CarouselContainer>
          <Section {...sectionsProprietary} onClick={() => window.location.assign('https://negocios.yogha.com.br/')
          } />
          <S.CarouselContainer style={{ marginTop: 20 }}>
            {breakpoint && <S.TitleCarrousel>
              Conheça as unidades Yogha
            </S.TitleCarrousel>}
            {breakpoint ? <MobileCarouselProperties hasFrom link card='building' data={buildingsWithLink} titleCarousel='Conheça as unidades Yogha' type='unit' /> : <ImageCarousel hasFrom type='unit' card='building' titleCarousel='Conheça as unidades Yogha' link data={buildingsWithLink} />}
          </S.CarouselContainer>
          <Section {...sectionsIncorporators} onClick={() => navigate('/incorporadores/')} />
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', width: '100%', backgroundColor: '#f6f4f4' }}>
            {breakpoint ? <MobileCarouselComments comments={comments} /> : <SliderComments comments={comments} />}
          </div>
          <S.CarouselContainer style={{ marginBottom: 10, marginTop: 80 }}>
            {breakpoint && (
              <S.TitleCarrousel>
                Blog da Yogha
              </S.TitleCarrousel>
            )}
            {breakpoint ? <MobileCarouselProperties link card='post' data={postsWithLink} titleCarousel='Blog da Yogha' type='post' /> : <ImageCarousel type='post' link card='post' titleCarousel='Blog da Yogha' data={postsWithLink} />}
          </S.CarouselContainer>
        </>
      )}
      <WhatsappButton />
    </Container>
  )
}

