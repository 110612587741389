import * as S from './styles';

type ToggleProps = {
    name: string;
    onChange: (value: boolean) => void;
    value: boolean;
}

export const Toggle = ({ name, value, onChange }: ToggleProps) => {

    return (
        <S.Content id={name} onClick={() => onChange(!value)} active={value}>
            <S.Toggle active={value} />
        </S.Content>
    );
};
