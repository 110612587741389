import { Building } from "components/Header";
import { GetProprierties, Property } from "../@types/Units/types";

export function mapGetProperties(properties: GetProprierties[]): Record<string, any>[] {
    if (!Array.isArray(properties)) {
      return [];
    }
    return properties.map(property => ({
        accommodation_id: property.ref_stays,
        id: property.id,
        name_property: property.name,
        title: property.name_ad,
        status: property.active,
        maxGuests: property.max_guest_capacity,
        address:{
          region: property.building_area,
          city: property.town,
          street: property.building_address,
          streetNumber: property.building_street_number,
          zip: property.postal_code,
        },
        emphasys: property.emphasys,
        uniqueValue: property.uniqueValue,
        thumbnail: linkImage + property.featured_image,
        building_yogha: property.building_yogha,
        rooms: property.rooms
    }));
  }

export const linkImage= 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/800x800/filters:quality(65)/'

export function mapProperties(properties: Property[]): Record<string, any>[] {
    if (!Array.isArray(properties)) {
      return [];
    }
    return properties.map(property => ({
        accommodation_id: property._id,
        id: property.id,
        title: property._mstitle?.pt_BR,
        description: property._msdesc?.pt_BR,
        status: property.status,
        maxGuests: property._i_maxGuests,
        rooms: property._i_rooms,
        beds: property._i_beds,
        bathrooms: property._f_bathrooms,
        address: property.address,
        latLng: property.latLng,
        currency: property.deff_curr,
        instantBooking: property.instantBooking,
        thumbnail : property._t_mainImageMeta ? property._t_mainImageMeta.url : 'default-url',
    }));
  }

  export function mapBuilding(buildings: Building[]): Record<string, any>[] {
    if (!Array.isArray(buildings)) {
      return [];
    }
    return buildings.map(building => ({
      id: building.id,
      address: {
        street: building.address,
        streetNumber: building.street_number,
        zip: building.postal_code,
        region:building.area,
        city: building.town,
      },
      title: building.name,
      payment: building.payment,
      active: building.active,
      refStays: building.ref_stays,
      propertyType: building.property_type,
      statusProperty: building.status_property,
      latitude: building.latitude,
      longitude: building.longitude,
      description: building.description,
      country_id: building.country_id,
      stateId: building.state_id,
      accommodation_id: building.building_id,
      thumbnail: linkImage + building.link_doc,
      uniqueValue: building.minValueAccommodation,
      buildingYogha: building.building_yogha,
      nearby_landmark: building.nearby_landmark,
			nearby_landmark_distance: building.nearby_landmark_distance,
    }));
  }

  export type CardProps = {
    accommodation_id?: string | never;
    id: string;
    title: string;
    thumbnail: string;
    location?: string;
    address:{
      region: string,
      city: string,
      street?: string,
      streetNumber?: number,
      zip?: string,
    },
    uniqueValue?: number;
    description?: string;
    hasFrom?: boolean;
    cardType: 'imoveis' | 'posts' | 'link' | 'search';
    onClick?: (arg?: any) => void | any;
    imageLink?: string;
    linkText?: string;
    buttonText: string;
    gadget?: string;
    disableClick?: boolean;
  };