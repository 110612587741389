import * as React from "react";
import * as PS from "../styles";
import * as S from "./styles";
import { CardProps } from "../types";
import { AnimatePresence } from "framer-motion";
import { linkImage } from "utils/formatterArray";

export const Default = ({ onNavigate, cardSelected, cardSelectedBuilding, referencePoint, type, ...card }: CardProps & { onNavigate?: (arg?: any) => void, cardSelected: any, type: string, cardSelectedBuilding: any, onMobileClick?: any, isDragging?: boolean }) => {

  const [imageIndex, setImageIndex] = React.useState(1); // Começa em 1 para ignorar a imagem clonada no início
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const transitionRef = React.useRef<boolean>(true);

  const roomImages = card.rooms?.map(room => linkImage + room.image_path);
  const cardImages = roomImages ? [card.thumbnail, ...roomImages] : [card.thumbnail];

  // Adiciona uma cópia da última imagem no início e da primeira imagem no final para criar o loop infinito
  const infiniteImages = [cardImages[cardImages.length - 1], ...cardImages, cardImages[0]]

  const startImageRotation = () => {
    if (card.isDragging || intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setImageIndex(prevIndex => prevIndex + 1);
    }, 3500);
  };

  const stopImageRotation = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setImageIndex(1); // Volta para a segunda imagem no array infinito
  };

  const titleLimiter = (title: string) => {
    if (title?.length > totalLength) {
      return title.slice(0, totalLength) + '...'
    } else {
      return title
    }
  }

  const totalLength = 44 - 10

  const handleNavigate = (e: React.MouseEvent) => {
    if (type === 'default') {
      cardSelected(card.accommodation_id)
      cardSelectedBuilding(card.building_yogha)
    } else if (type === 'unit') {
      cardSelected(card.title)
    }

  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

  };

  React.useEffect(() => {
    // Verifica se estamos no final ou no início das imagens duplicadas para ajustar o índice
    if (imageIndex === infiniteImages.length - 1) {
      setTimeout(() => {
        transitionRef.current = false; // Desativa a transição temporariamente
        setImageIndex(1); // Volta para a primeira imagem real
      }, 500); // Aguarda o final da transição antes de "teleportar" para a primeira imagem
    } else if (imageIndex === 0) {
      setTimeout(() => {
        transitionRef.current = false; // Desativa a transição temporariamente
        setImageIndex(infiniteImages.length - 2); // Vai para a última imagem real
      }, 500);
    } else {
      transitionRef.current = true; // Habilita a transição novamente
    }
  }, [imageIndex, infiniteImages.length]);

  React.useEffect(() => {
    if (card.isDragging) {
      stopImageRotation();
    }
  }, [card.isDragging]);

  return (
    <>
      <PS.GlobalContainer onMouseEnter={card.isDragging ? () => { } : startImageRotation} onMouseLeave={card.isDragging ? () => { } : stopImageRotation}>
        <PS.Container onClick={card.onMobileClick} draggable='false' onMouseDown={handleNavigate} >
          {card.gadget && <PS.Gadget header><p>{card.gadget}</p></PS.Gadget>}
          <S.ImageWrapper>
            <AnimatePresence mode="wait">
              <S.ImageContainer translateValue={-imageIndex * 100} transition={transitionRef.current}>
                {infiniteImages.map((image, index) => (
                  <S.Image
                    key={index}
                    src={image}
                    style={{ width: "100%", height: "100%" }}
                    draggable="false"
                    onMouseDown={handleMouseDown}
                  />
                ))}
              </S.ImageContainer>
            </AnimatePresence>
          </S.ImageWrapper>
          <S.AddressContainer type={type} hasFrom={card.hasFrom}>
            <PS.TextTitle  >{titleLimiter(card.title)}</PS.TextTitle>
            <PS.AddressText>{titleLimiter(`${card.address.region} - ${card.address.city}, ${card.address.city === 'Curitiba' && 'PR'}`)}
            </PS.AddressText>
            {referencePoint !== 'undefined undefined' && <PS.AddressText>{referencePoint} </PS.AddressText>}
            {card.location !== null && <PS.AddressText>{titleLimiter(card.location ? card.location : '')}</PS.AddressText>}
          </S.AddressContainer>
          <S.ValueContainer>{card.hasFrom && <PS.TextFrom>A partir de:</PS.TextFrom>}
            <S.ValueContent>
              <PS.TextPrice>{`R$ ${card.uniqueValue?.toFixed(0)},00 `}</PS.TextPrice>
              <PS.TextOthers>noite (+taxas)</PS.TextOthers>
              {card.description !== null && <PS.TextOthers>{card.description}</PS.TextOthers>}
            </S.ValueContent>
          </S.ValueContainer>

        </PS.Container>
      </PS.GlobalContainer>
    </>
  );
};